import React from "react";
import PropTypes from "prop-types";

const Localization = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 18 17"
  >
    <path
      fill={fill || "#6A6A6A"}
      fillRule="evenodd"
      d="M0 10.31h8v1.126H0V10.31zm2.845-6.874c0 .312.115.578.344.798.23.22.502.33.818.33.316 0 .586-.11.81-.33.225-.22.338-.486.338-.798a1.11 1.11 0 0 0-.344-.81A1.11 1.11 0 0 0 4 2.281a1.11 1.11 0 0 0-.811.343c-.23.23-.344.5-.344.811zm4.591 0c0 .477-.119 1.022-.357 1.636a10.83 10.83 0 0 1-.852 1.69c-.293.477-.632.972-1.017 1.485-.275.367-.56.72-.853 1.059L4 9.718l-.357-.412c-.294-.34-.578-.692-.853-1.059a19.659 19.659 0 0 1-1.017-1.484 10.83 10.83 0 0 1-.852-1.69C.683 4.457.564 3.912.564 3.435c0-.623.153-1.198.46-1.725a3.412 3.412 0 0 1 1.25-1.25A3.366 3.366 0 0 1 4 0c.623 0 1.198.153 1.725.46.527.307.944.724 1.25 1.251.308.527.461 1.102.461 1.725z"
    />
  </svg>
);

Localization.propTypes = {
  fill: PropTypes.string
};

Localization.defaultProps = {
  fill: ""
};

export default Localization;
