import React from "react";
import PropTypes from "prop-types";
import reverseGeocode from "../utils/reverseGeocode";
import { useStateValue } from "../state/StateProvider";

function UserLocation({ onCoordsChange }) {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateValue();

  const handleClick = e => {
    e.preventDefault();
    if (!window.navigator.geolocation) return;

    window.navigator.geolocation.getCurrentPosition(
      async ({ coords: { latitude, longitude } }) => {
        const location = { lat: latitude, lng: longitude };
        onCoordsChange({ location });

        const latlng = `${latitude},${longitude}`;
        const placeholderCandidates = await reverseGeocode(latlng);
        if (placeholderCandidates.length) {
          const locationPlaceholder =
            placeholderCandidates[0].formatted_address;
          dispatch({ type: "setLocationPlaceholder", locationPlaceholder });
        }
      }
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={handleClick}>
      Search around me
    </a>
  );
}

UserLocation.propTypes = {
  onCoordsChange: PropTypes.func.isRequired
};

export default UserLocation;
