import React from "react";
import PropTypes from "prop-types";

const ArrowNext = ({ primary, width, height, style }) => (
  <span style={{ display: "inline-flex", alignItems: "center", ...style }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "12"}
      height={height || "12"}
      viewBox="0 0 12 12"
    >
      <defs>
        <filter
          id="a"
          width="187.5%"
          height="187.5%"
          x="-43.8%"
          y="-43.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" stroke="#DFDFDF" d="M-628.5-38.5h716v1890h-716z" />
        <g filter="url(#a)" transform="translate(-14 -14)">
          <circle cx="20" cy="20" r="20" fill="#FFF" />
          <path
            fill={primary || "#FF8D02"}
            fillRule="nonzero"
            d="M18.32 15l5 5-5 5-1.18-1.18L20.98 20l-3.84-3.82z"
          />
        </g>
      </g>
    </svg>
  </span>
);

ArrowNext.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ArrowNext.defaultProps = {
  primary: "",
  width: "",
  height: "",
  style: { display: "inline-flex", alignItems: "center" }
};

export default ArrowNext;
