import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Button } from "reactstrap";
import {
  XAxis,
  YAxis,
  BarChart,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip
} from "recharts";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import intl from "react-intl-universal";
import ArrowNext from "../assets/svg/ArrowNext";
import {
  nextSaturday,
  isSameDay,
  isSameMonth,
  isSameYear
} from "../utils/date";
import groupBy from "../utils/groupBy";

// eslint-disable-next-line react/prop-types
const CustomTooltip = ({ payload, active, currency, className }) =>
  active && payload[0] ? (
    <div className={`custom-tooltip p-3 m-3 bg-white shadow-lg ${className}`}>
      <p className="label">
        {payload[0].payload.price.toLocaleString(currency, {
          style: "currency",
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })}
      </p>
    </div>
  ) : null;

const StyledCustomToolTip = styled(CustomTooltip)`
  font-weight: 400;
  font-size: 1.5em;
  margin-right: 0.5em;
  color: ${props => props.theme.primary || "#ff8d02"};
  text-align: left;
  @media (min-width: 768px) {
    text-align: ${props => props.textAlignDesktop};
  }
`;

const Charts = ({ prices, onDatesChange, currency, theme }) => {
  // create an array of objects
  // 1 for every week in two years ( 104 )
  // which have the same keys than the prices
  let i = 0;
  let saturday = nextSaturday(moment());
  const weekCount = 104;
  const year = [];

  // eslint-disable-next-line no-plusplus
  for (i; i < weekCount; i++) {
    const to = nextSaturday(saturday);
    year.push({ from: saturday, to, price: 0 });
    saturday = to;
  }

  // only interesting price data
  const pricesData = prices.map(({ from, to, ...price }) => ({
    from,
    to,
    price: price[currency.toLowerCase()]
  }));

  // fill the year array with the available prices
  const yearlyAvailibilities = year.map(
    week =>
      pricesData.filter(({ from }) => isSameDay(from, week.from))[0] || {
        from: week.from,
        to: week.to,
        price: null
      }
  );

  // finding the first price
  const firstAvailibility = yearlyAvailibilities.find(w => w.price != null);
  const lastAvailibility = yearlyAvailibilities
    .slice() // creates a new array to avoid mutation by reverse()
    .reverse()
    .find(w => w.price != null);
  const highestPrice = Math.max(
    ...yearlyAvailibilities
      .filter(p => p.price !== null)
      .map(({ price }) => price)
  );

  // setting Year to display
  const [chosenYear, setChosenYear] = useState(
    firstAvailibility.from.format("YYYY")
  );
  const [chosenMonth, setChosenMonth] = useState(
    firstAvailibility.from.format("MM")
  );
  // condional for displaying months or only full year
  const [monthsActive, setMonthsActive] = useState(false);

  const localizedPriceWithLabel = yearlyAvailibilities.map(el => {
    const label = moment(el.from).format(`${monthsActive ? "MM-Do" : "MMM"}`);
    return { ...el, label };
  });

  // filtering when user change year or months
  const dataForPeriod = localizedPriceWithLabel.filter(el =>
    monthsActive
      ? isSameYear(el.from, chosenYear) &&
        isSameMonth(el.from.format("MM"), chosenMonth)
      : isSameYear(el.from, chosenYear)
  );

  // source for BarChart
  let data;
  if (monthsActive) {
    data = dataForPeriod;
  } else {
    const groupedByMonths = Object.values(groupBy(dataForPeriod, "label"));
    data = groupedByMonths.map(group => {
      const minPrice = Math.min(
        ...group.map(({ price }) => price).filter(Boolean)
      );
      return group.find(({ price }) => price === minPrice) || group[0];
    });
  }

  const getPreviousMonth = () => {
    if (
      chosenMonth === firstAvailibility.from.format("MM") &&
      chosenYear === firstAvailibility.from.format("YYYY")
    )
      return;
    if (chosenMonth === "01") {
      setChosenYear(
        moment(chosenYear)
          .subtract("1", "year")
          .format("YYYY")
      );
    }
    setChosenMonth(
      moment(chosenMonth)
        .subtract("1", "month")
        .format("MM")
    );
  };

  const getPreviousYear = () => {
    if (chosenYear === firstAvailibility.from.format("YYYY")) return;
    setChosenYear(
      moment(chosenYear)
        .subtract("1", "year")
        .format("YYYY")
    );
  };

  const getNextMonth = () => {
    if (
      chosenMonth === lastAvailibility.from.format("MM") &&
      chosenYear === lastAvailibility.from.format("YYYY")
    )
      return;
    if (chosenMonth === "12") {
      setChosenYear(
        moment(chosenYear)
          .add("1", "year")
          .format("YYYY")
      );
    }
    setChosenMonth(
      moment(chosenMonth)
        .add("1", "month")
        .format("MM")
    );
  };

  const getNextYear = () => {
    if (chosenYear === lastAvailibility.from.format("YYYY")) return;

    setChosenYear(
      moment(chosenYear)
        .add("1", "year")
        .format("YYYY")
    );
    setChosenMonth(
      moment()
        .startOf(chosenYear)
        .format("MM")
    );
  };

  return (
    <div>
      <div
        className="mb-5 d-flex justify-content-between"
        style={{
          borderBottom: "1px solid grey"
        }}
      >
        <div style={{ display: "flex" }}>
          <Button
            style={{
              backgroundColor: "white",
              border: "none",
              borderRadius: "0",
              color: monthsActive ? "grey" : theme.primary,
              borderBottom: monthsActive ? "none" : `2px solid ${theme.primary}`
            }}
            color="primary"
            onClick={() => setMonthsActive(false)}
          >
            {intl.get("YEARS_UNIT").toUpperCase()}
          </Button>
          <Button
            style={{
              backgroundColor: "white",
              border: "none",
              borderRadius: "0",
              color: monthsActive ? theme.primary : "grey",
              borderBottom: monthsActive ? `2px solid ${theme.primary}` : "none"
            }}
            color="primary"
            onClick={() => {
              setChosenMonth(
                moment(
                  yearlyAvailibilities
                    .slice()
                    .filter(a => isSameYear(a.from, chosenYear))
                    .find(w => w.price != null).from
                ).format("MM")
              );
              setMonthsActive(true);
            }}
          >
            {intl.get("MONTH").toUpperCase()}
          </Button>
        </div>
        <div className="d-flex align-items-center">
          <span
            role="button"
            tabIndex="0"
            onKeyPress={monthsActive ? getPreviousMonth : getPreviousYear}
            onClick={monthsActive ? getPreviousMonth : getPreviousYear}
          >
            <ArrowNext
              primary={theme.primary}
              style={{ height: "2em", transform: "rotate(180deg)" }}
            />
          </span>

          <span
            style={{
              color: theme.primary,
              fontSize: "2em",
              fontWeight: "bold"
            }}
          >
            {monthsActive
              ? `${moment.monthsShort(chosenMonth - 1)} ${moment(
                  chosenYear
                ).format("YYYY")}`
              : moment(chosenYear).format("YYYY")}
          </span>

          <span
            role="button"
            tabIndex="0"
            onKeyPress={monthsActive ? getPreviousMonth : getPreviousYear}
            onClick={monthsActive ? getNextMonth : getNextYear}
          >
            <ArrowNext primary={theme.primary} style={{ height: "2em" }} />
          </span>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          onClick={e => {
            onDatesChange({
              startDate: e.activePayload[0].payload.from,
              endDate: e.activePayload[0].payload.to
            });
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis type="number" domain={[0, Math.round(highestPrice * 1.1)]} />
          <Tooltip
            content={<StyledCustomToolTip theme={theme} currency={currency} />}
          />
          <Bar dataKey="price" fill={theme.primary} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

Charts.propTypes = {
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      from: momentPropTypes.momentObj,
      to: momentPropTypes.momentObj,
      discountPrice: PropTypes.number
    })
  ).isRequired,
  onDatesChange: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  theme: PropTypes.shape({ primary: PropTypes.string }).isRequired
};

export default Charts;
