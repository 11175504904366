import { START_DATE } from "react-dates/constants";
import { isSameSaturdayWeek } from "./date";

function onFocusChange(focusedInput) {
  this.setState({
    // Force the focusedInput to always be truthy so that dates are always selectable
    focusedInput: !focusedInput ? START_DATE : focusedInput
  });
}

const dayBlockedFromDates = fromDates => day =>
  !fromDates.some(fromDate => isSameSaturdayWeek(day, fromDate));

export { onFocusChange, dayBlockedFromDates };
