import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledWrapper = styled.div`
  background-color: lightgray;
  height: 100%;
`;

const Placeholder = () => (
  <svg
    fill="#666666"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
    style={{
      width: "80%",
      height: "80%",
      margin: "0 auto",
      display: "block",
      paddingTop: "10%"
    }}
  >
    <path d="M93,75h-5.497C59.507,63.06,46.108,32.815,44.239,16.332c-0.004-0.037-0.025-0.067-0.033-0.102  c0.793-0.365,1.346-1.161,1.346-2.091c0-1.008-0.651-1.856-1.552-2.169V6c0-0.552-0.447-1-1-1s-1,0.448-1,1v6.203  c-0.636,0.41-1.06,1.122-1.06,1.936s0.424,1.525,1.06,1.936v9.032l-4.592,7.581c-1.397-2.265-3.897-3.72-6.615-3.72  c-2.753,0-5.236,1.43-6.632,3.724c-0.919-0.56-1.97-0.871-3.069-0.871c-3.11,0-5.662,2.411-5.898,5.461  c-0.81-0.293-1.662-0.461-2.54-0.461c-4.127,0-7.485,3.358-7.485,7.485c0,0.844,0.088,2.06,0.505,3.063  C5.832,47.753,6.294,47.973,7,48h15c0.553,0,1-0.448,1-1s-0.447-1-1-1H7.341c-0.109-0.482-0.173-1.064-0.173-1.694  c0-3.024,2.461-5.485,5.485-5.485c1.114,0,2.188,0.332,3.102,0.96c0.331,0.228,0.85,0.297,1.175,0.066  c0.312-0.222,0.439-0.737,0.361-1.136c-0.063-0.317-0.12-0.639-0.12-0.969c0-2.163,1.76-3.922,3.922-3.922  c1.021,0,1.989,0.391,2.725,1.1c0.213,0.205,0.517,0.341,0.807,0.299c0.335-0.048,0.686-0.328,0.814-0.643  c0.889-2.192,2.99-3.608,5.355-3.608c2.384,0,4.554,1.496,5.401,3.724L8.578,80.283C8.271,80.875,8.193,81.48,8.573,82l8.175,8.788  C18.004,92.122,19.776,93,21.61,93h46.743c1.834,0,3.606-0.878,4.862-2.212l8.264-8.825c0.309-0.33,0.392-0.839,0.211-1.254  C81.51,80.295,81.101,80,80.648,80H44v-3h49c0.553,0,1-0.447,1-1S93.553,75,93,75z M42,72.175c-5.996-13.011-2.535-30.865,0-40.188  V72.175z M39.663,32.824c-2.742,10.474-5.918,29.268,1.735,42.557c-16.642,0.103-24.57,1.643-29.646,3.522L39.663,32.824z   M71.761,89.418C70.88,90.353,69.639,91,68.354,91H21.61c-1.285,0-2.526-0.647-3.407-1.582L11.317,82h67.329L71.761,89.418z   M14.896,80C19.925,79,27.81,77.439,42,77.379V80H14.896z M44,75V25.279C48.51,41.669,60.893,63.842,82.766,75H44z M31,84  c0-0.553,0.447-1,1-1h25c0.553,0,1,0.447,1,1s-0.447,1-1,1H32C31.447,85,31,84.553,31,84z M17,52c-0.553,0-1-0.447-1-1  c0-0.552,0.447-1,1-1h7c0.553,0,1,0.448,1,1c0,0.553-0.447,1-1,1H17z M61,84c0-0.553,0.447-1,1-1h5c0.553,0,1,0.447,1,1  s-0.447,1-1,1h-5C61.447,85,61,84.553,61,84z" />
    <path d="M95.101,38.542c0,1.292-0.206,3.458-1.586,3.458H79c-0.553,0-1-0.448-1-1s0.447-1,1-1h13.944  c0.08-0.367,0.156-0.941,0.156-1.458c0-1.543-0.657-3.031-1.759-3.981c-1.03-0.889-2.035-1.102-3.605-1.051  c-0.925,0.03-1.354-0.942-1.367-0.962c-1.146-1.796-3.023-2.488-5.279-2.488c-2.992,0-5.493,2.143-6.026,5.08  c-0.087,0.479-0.341,0.753-0.66,0.855c-0.318,0.102-0.74-0.052-0.992-0.277c-1.119-0.999-2.563-1.549-4.065-1.549  c-3.371,0-6.113,2.742-6.113,6.113c0,0.378,0.035,0.758,0.104,1.131c0.101,0.543-0.258,1.065-0.801,1.166  c-0.062,0.011-0.123,0.017-0.184,0.017c-0.473,0-0.893-0.336-0.982-0.817c-0.091-0.493-0.138-0.996-0.138-1.496  c0-4.474,3.64-8.113,8.113-8.113c1.473,0,2.902,0.401,4.148,1.144c1.165-3.117,4.15-5.254,7.595-5.254  c2.913,0,5.505,1.304,6.948,3.425c1.73-0.071,3.342,0.468,4.609,1.561C94.184,34.371,95.101,36.426,95.101,38.542z M87,43H66  c-0.553,0-1,0.448-1,1s0.447,1,1,1h21c0.553,0,1-0.448,1-1S87.553,43,87,43z M74,57c-0.553,0-1,0.447-1,1s0.447,1,1,1h7  c0.553,0,1-0.447,1-1s-0.447-1-1-1H74z M90,54c0-0.553-0.447-1-1-1H78c-0.553,0-1,0.447-1,1s0.447,1,1,1h11  C89.553,55,90,54.553,90,54z" />
  </svg>
);

const BoatImagePlaceholder = ({ className }) => (
  <StyledWrapper className={className}>
    <Placeholder />
  </StyledWrapper>
);

BoatImagePlaceholder.propTypes = {
  className: PropTypes.string
};

BoatImagePlaceholder.defaultProps = {
  className: ""
};

export default BoatImagePlaceholder;
