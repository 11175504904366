import React from "react";
import PropTypes from "prop-types";

const Loa = ({ primary, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-547-589H477v958H-547z" />
      <path fill="#FFF" stroke="#DFDFDF" d="M-24.5-270.5h229v319h-229z" />
      <path
        fill={primary || "#FF9D23"}
        d="M11.5 11.074H3.24l2.086 2.1L4.5 14 1 10.5 4.5 7l.826.826-2.086 2.1h13.02l-2.086-2.1L15 7l3.5 3.5L15 14l-.826-.826 2.086-2.1H11.5z"
      />
    </g>
  </svg>
);

Loa.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Loa.defaultProps = {
  primary: "",
  width: "",
  height: ""
};

export default Loa;
