import React from "react";
import PropTypes from "prop-types";
import Geosuggest from "react-geosuggest";
import UserLocation from "./UserLocation";
import { useStateValue } from "../state/StateProvider";

function LocationSelect({ onSuggestSelect }) {
  const [{ theme, locationPlaceholder }, dispatch] = useStateValue();

  const handleSuggestSelect = suggest => {
    if (suggest && suggest.label) {
      dispatch({
        type: "setLocationPlaceholder",
        locationPlaceholder: suggest.label
      });
      onSuggestSelect(suggest);
    }
  };

  return (
    <div>
      <Geosuggest
        initialValue={locationPlaceholder}
        onSuggestSelect={handleSuggestSelect}
        autoFocus={!locationPlaceholder}
        className="w-100 m-0"
        style={{
          input: {
            border: `2px solid ${theme.primary}`,
            boxShadow: "unset",
            color: "#1B5679",
            height: "40px"
          }
        }}
      />
      <UserLocation onCoordsChange={onSuggestSelect} />
    </div>
  );
}

LocationSelect.propTypes = {
  onSuggestSelect: PropTypes.func
};

LocationSelect.defaultProps = {
  onSuggestSelect: () => {}
};

export default LocationSelect;
