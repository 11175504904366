import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./BoatPageModelInfo.css";

const StyledModelInfo = styled.span`
  border: 1px solid rgba(223, 223, 223, 0.61);
  height: 7em;
  width: 7em;
  margin: 0.5em;
`;

const BoatPageModelInfo = ({ value, unit, icon, unit2 }) => {
  if (!value) return null;

  return (
    <StyledModelInfo
      style={{ justifyContent: "center" }}
      className="model-info px-1"
    >
      <div className="d-flex justify-content-center">{icon}</div>
      <span
        className="model-info-value"
        style={{ color: "#6d7278", fontSize: "10px", fontWeight: "500" }}
      >
        {`${unit.charAt(0).toUpperCase() + unit.slice(1)}: `}
        <span
          className="model-info-value"
          style={{ color: "#6d7278", fontSize: "10px", fontWeight: "200" }}
        >
          {`${value}`} {unit2 && `${unit2}`}
        </span>
      </span>
    </StyledModelInfo>
  );
};

BoatPageModelInfo.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.string,
  unit2: PropTypes.string,
  icon: PropTypes.node
};

BoatPageModelInfo.defaultProps = {
  value: 40,
  unit: "",
  unit2: "",
  icon: <span />
};

export default BoatPageModelInfo;
