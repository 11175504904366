import React from "react";
import PropTypes from "prop-types";

const Cabin = ({ primary, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-601-589H423v958H-601z" />
      <path fill="#FFF" stroke="#DFDFDF" d="M-78.5-270.5h229v319h-229z" />
      <path
        fill={primary || "#FF9D23"}
        fillRule="nonzero"
        d="M6.5 8.75h2v1.5h-2v5.25h7v-12h-7v5.25zM5 2h10v15H5V2z"
      />
    </g>
  </svg>
);

Cabin.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Cabin.defaultProps = {
  primary: "",
  width: "",
  height: ""
};

export default Cabin;
