import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IS_EMBEDDED } from "../constants";
import scrollToTopOfIframe from "../utils/iframe";

class ModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      node: null
    };

    this.toggle = this.toggle.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  setRef(node) {
    this.setState({ node });
  }

  toggle() {
    scrollToTopOfIframe();
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      children,
      title,
      isDesktop,
      modalFooter,
      positionedModal,
      toggleButton,
      width
    } = this.props;

    const positionedModalStyle =
      positionedModal && isDesktop && this.state.node
        ? {
            position: "absolute",
            top: `${this.state.node.getBoundingClientRect().bottom}px`,
            left: `${this.state.node.getBoundingClientRect().left}px`,
            width
          }
        : {};
    const fullScreenModalStyle =
      positionedModal && !isDesktop ? { margin: "0", maxWidth: "unset" } : {};
    const ActionButton = styled.div`
      position: ${!IS_EMBEDDED && "sticky"};
      bottom: 0;
      background-color: white;
    `;
    return (
      <>
        <div
          role="button"
          tabIndex="0"
          onKeyPress={this.toggle}
          onClick={this.toggle}
          ref={this.setRef}
          style={{ margin: "auto", marginBottom: "unset" }}
        >
          {toggleButton}
        </div>
        <Modal
          fade={false}
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          backdropClassName="bg-white"
          style={{
            ...positionedModalStyle,
            ...fullScreenModalStyle
          }}
          contentClassName={(!isDesktop && !IS_EMBEDDED && "h-100 w-100") || ""}
        >
          <ModalHeader
            style={{ borderBottom: "0", paddingBottom: "0" }}
            toggle={this.toggle}
          >
            {title}
          </ModalHeader>
          <ModalBody
            style={{
              justifyContent: "center",
              flexDirection: "column",
              display: "flex",
              backgroundColor: "white"
            }}
          >
            {children && children(this.toggle)}
          </ModalBody>
          <ActionButton
            role="button"
            tabIndex="0"
            onKeyPress={this.toggle}
            onClick={this.toggle}
          >
            {!isDesktop && modalFooter && (
              <ModalFooter>{modalFooter}</ModalFooter>
            )}
          </ActionButton>
        </Modal>
      </>
    );
  }
}

ModalComponent.propTypes = {
  children: PropTypes.func,
  title: PropTypes.string,
  toggleButton: PropTypes.node.isRequired,
  isDesktop: PropTypes.bool,
  modalFooter: PropTypes.node,
  positionedModal: PropTypes.bool,
  width: PropTypes.string
};

ModalComponent.defaultProps = {
  children: () => {},
  title: "",
  isDesktop: false,
  modalFooter: null,
  positionedModal: false,
  width: "inherit"
};

export default ModalComponent;
