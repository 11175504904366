import React from "react";
import PropTypes from "prop-types";

const Year = ({ primary, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "32"}
    height={height || "32"}
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" stroke="#DFDFDF" d="M-245.5-222.5h716v1419h-716z" />
      <path
        fill={primary || "#FF8D02"}
        d="M3 17.425l3.842.586a9.6 9.6 0 0 0 .553 1.336l-2.302 3.13 3.43 3.43 3.13-2.302a9.6 9.6 0 0 0 1.336.553L13.575 28h4.85l.586-3.842a9.6 9.6 0 0 0 1.336-.553l3.13 2.302 3.43-3.43-2.302-3.13a9.59 9.59 0 0 0 .553-1.336L29 17.425v-4.85l-3.842-.586a9.6 9.6 0 0 0-.553-1.336l2.302-3.13-3.43-3.43-3.13 2.302a9.59 9.59 0 0 0-1.336-.553L18.425 2h-4.85l-.586 3.842a9.6 9.6 0 0 0-1.336.553l-3.13-2.302-3.43 3.43 2.302 3.13a9.59 9.59 0 0 0-.553 1.336L3 12.575v4.85zm4.095 4.9l2.107-2.864-.247-.43a8.072 8.072 0 0 1-.787-1.9l-.13-.479-3.515-.535v-2.234l3.515-.535.13-.48c.18-.664.445-1.303.787-1.899l.247-.43-2.107-2.865 1.58-1.579 2.864 2.107.43-.247a8.072 8.072 0 0 1 1.9-.787l.479-.13.535-3.515h2.234l.535 3.515.48.13c.664.18 1.303.445 1.899.787l.43.247 2.865-2.107 1.579 1.58-2.107 2.864.247.43c.342.596.607 1.235.787 1.9l.13.479 3.515.535v2.234l-3.515.535-.13.48a8.07 8.07 0 0 1-.787 1.899l-.247.43 2.107 2.865-1.58 1.579-2.864-2.107-.43.247a8.079 8.079 0 0 1-1.9.787l-.479.13-.535 3.515h-2.234l-.535-3.515-.48-.13a8.072 8.072 0 0 1-1.899-.787l-.43-.247-2.865 2.107-1.579-1.58zM16 8.5A6.507 6.507 0 0 0 9.5 15c0 3.584 2.916 6.5 6.5 6.5s6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5zm0 11.452A4.958 4.958 0 0 1 11.048 15 4.958 4.958 0 0 1 16 10.048 4.958 4.958 0 0 1 20.952 15 4.958 4.958 0 0 1 16 19.952zm3.542-3.102l-.83 1.129L15.3 15.47V11.3h1.4v3.462l2.842 2.088z"
      />
      <path stroke="#DFDFDF" strokeOpacity=".607" d="M-23.5-15.5h79v79h-79z" />
    </g>
  </svg>
);

Year.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Year.defaultProps = {
  primary: "",
  width: "",
  height: ""
};

export default Year;
