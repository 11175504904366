import React, { useState } from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import Select from "react-select";
import { useStateValue } from "../state/StateProvider";

const DurationSelect = ({ isOptionDisabled }) => {
  const options = [7, 14].map(days => {
    return {
      value: days,
      label: `${days} ${intl.get("DURATION_SELECT.DAYS")}`
    };
  });

  const [{ durationInDays, theme }, dispatch] = useStateValue();
  const preselected = options.find(({ value }) => value === durationInDays);
  const [value, setValue] = useState(preselected);

  const handleChange = selected => {
    if (isOptionDisabled(selected.value)) return;

    setValue(selected);
    dispatch({ type: "changeDuration", durationInDays: selected.value });
  };

  const customStyles = {
    container: provided => ({
      ...provided,
      marginTop: "auto",
      marginRight: " auto",
      marginBottom: " unset",
      marginLeft: " auto",
      width: "100%",
      zIndex: "10"
    }),
    control: provided => ({
      ...provided,
      height: "40px",
      borderRadius: "0",
      border: `2px solid ${theme.primary}`,
      minHeight: "unset",
      alignContent: "center"
    })
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      options={options}
      styles={customStyles}
    />
  );
};

DurationSelect.propTypes = {
  isOptionDisabled: PropTypes.func
};

DurationSelect.defaultProps = {
  isOptionDisabled: () => {}
};

export default DurationSelect;
