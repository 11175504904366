import PropTypes from "prop-types";

const Location = ({ city, country }) => {
  if (!city && !country) return null;
  return country && city ? `${city}, ${country}` : `${city || country}`;
};

Location.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string
};

Location.defaultProps = {
  city: "",
  country: ""
};

export default Location;
