import React from "react";
import { Button } from "reactstrap";
import { ConnectedDisplayHits } from "./DisplayHits";

const ModalFooter = () => (
  <Button outline block color="primary">
    <ConnectedDisplayHits />
  </Button>
);

export default ModalFooter;
