import React from "react";
import PropTypes from "prop-types";
import { VirtualRange, VirtualRefinementList } from "./VirtualRefinements";

const VirtualFilters = ({ priceRangeFacet, priceRefinement }) => (
  <div>
    <VirtualRange attribute="year" />
    <VirtualRange attribute="cabins" />
    <VirtualRange attribute="loa" />
    <VirtualRefinementList attribute="type" />
    <VirtualRange
      attribute={priceRangeFacet}
      defaultRefinement={priceRefinement}
    />
  </div>
);

VirtualFilters.propTypes = {
  priceRangeFacet: PropTypes.string,
  priceRefinement: PropTypes.shape({
    max: PropTypes.string,
    min: PropTypes.string
  })
};

VirtualFilters.defaultProps = {
  priceRangeFacet: "",
  priceRefinement: { max: null, min: null }
};
export default VirtualFilters;
