import React from "react";
import PropTypes from "prop-types";

function CollapsibleFilter({ title, children }) {
  const collapseRef = React.createRef();

  const toggle = () => {
    const { display } = collapseRef.current.style;
    collapseRef.current.style.display = display === "none" ? "" : "none";
  };

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div id="xxx" style={{ cursor: "pointer" }} onClick={toggle}>
        {title}
      </div>
      <div ref={collapseRef} style={{ display: "none" }}>
        {children}
      </div>
    </div>
  );
}

CollapsibleFilter.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default CollapsibleFilter;
