import React from "react";
import Select from "react-select";
import StateContext from "../state/context";
import getRate from "../utils/rate";

const options = [
  { value: "EUR", label: "Euro - €" },
  { value: "GBP", label: "GBP - £" },
  { value: "USD", label: "USD - $" }
];

export default class CurrencySwitcher extends React.Component {
  static contextType = StateContext;

  componentDidMount() {
    // eslint-disable-next-line no-empty-pattern
    const [{ currency }, dispatch] = this.context;
    getRate().then(data =>
      dispatch({
        type: "getRate",
        rate: data
      })
    );
    const URLParams = new URLSearchParams(window.location.search);
    this.currency = URLParams.get("curr") || currency || "EUR";
  }

  render() {
    const [{ theme }, dispatch] = this.context;

    const selectedOption = options.filter(o => o.value === this.currency)[0];
    const update = e => {
      dispatch({
        type: "changeCurrency",
        currency: e.value
      });
      this.currency = e.value;
    };

    const customStyles = {
      container: provided => ({
        ...provided,
        marginTop: "auto",
        marginRight: " auto",
        marginBottom: " unset",
        marginLeft: " auto",
        width: "100%"
      }),
      control: provided => ({
        ...provided,
        height: "40px",
        borderRadius: "0",
        border: `2px solid ${theme.primary}`,
        minHeight: "unset",
        alignContent: "center"
      })
    };

    return (
      <Select
        value={selectedOption}
        onChange={e => update(e)}
        options={options}
        styles={customStyles}
      />
    );
  }
}
