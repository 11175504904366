import moment from "moment";
import intl from "react-intl-universal";
import "moment/locale/fr";
import locales from "./locales";
import currentLocale from "./currentLocale";

const loadMomentLocale = locale => moment.locale(locale);

const loadReactIntlUniversalLocale = async locale =>
  intl.init({
    currentLocale: locale,
    locales
  });

async function loadLocales() {
  const locale = currentLocale();

  loadMomentLocale(locale);
  await loadReactIntlUniversalLocale(locale);
}

export default loadLocales;
