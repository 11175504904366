import React from "react";
import PropTypes from "prop-types";
import { ConnectedRefinementList } from "./ConnectedRefinementList";

const CheckboxesFilter = ({
  attribute,
  isItemDisplayed,
  translateItem,
  ...rest
}) => {
  const translate = items =>
    items
      .filter(isItemDisplayed)
      .map(item => ({ ...item, label: translateItem(item) }));

  const sortDescending = items =>
    // sort items by descending order of `count`
    translate(items).sort((item1, item2) => item1.count < item2.count);

  return (
    <div id="refinements">
      <ConnectedRefinementList
        attribute={attribute}
        limit={100}
        transformItems={sortDescending}
        {...rest}
      />
    </div>
  );
};

CheckboxesFilter.propTypes = {
  attribute: PropTypes.string.isRequired,
  isItemDisplayed: PropTypes.func,
  translateItem: PropTypes.func
};

CheckboxesFilter.defaultProps = {
  isItemDisplayed: () => true,
  translateItem: item => item.label
};

export default CheckboxesFilter;
