import addOrAppendParamToUrl from "../utils/urlHelpers";
import logolatesail from "../assets/images/logolatesail.png";
import { DEFAULT_DURATION_IN_DAYS } from "../constants";

const initialState = () => {
  const URLParams = new URLSearchParams(window.location.search);
  const currency = URLParams.get("curr") || "EUR";

  addOrAppendParamToUrl({
    urlParams: window.location.search,
    key: "curr",
    value: currency
  });

  return {
    currency,
    rate: null,
    message: null,
    mail: null,
    theme: { primary: "#0042ad" },
    site: null,
    group: null,
    poweredByLogo: logolatesail,
    durationInDays: DEFAULT_DURATION_IN_DAYS,
    locationPlaceholder: null
  };
};

export default initialState;
