import React from "react";
import intl from "react-intl-universal";
import humanizeString from "humanize-string";
import PropTypes from "prop-types";
import Check from "../assets/svg/Check";

const Equipment = ({ equipment, theme }) =>
  equipment ? (
    <div className="col-6 mt-3">
      <span>
        <Check primary={theme.primary} />
      </span>
      <span className="ml-2">
        {intl
          .get(`EQUIPMENT.${equipment.slug.toUpperCase()}`)
          .defaultMessage(humanizeString(equipment.slug))}
      </span>
    </div>
  ) : null;

Equipment.propTypes = {
  equipment: PropTypes.shape({ slug: PropTypes.string }),
  theme: PropTypes.shape({ primary: PropTypes.string }).isRequired
};

Equipment.defaultProps = {
  equipment: {
    slug: "gps"
  }
};

export default Equipment;
