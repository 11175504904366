import React from "react";
import PropTypes from "prop-types";
import { connectRefinementList } from "react-instantsearch-dom";
import styled from "styled-components";

const Refinement = styled.div`
  border-radius: 0.25rem;
  color: #6d7278;
  font-size: 0.8em !important;
  margin-bottom: 0.5rem;
  cursor: pointer;
`;

const RefinementList = ({ items, refine }) => (
  <div>
    {items.map(item => (
      <Refinement
        key={item.label}
        style={{ marginLeft: ".5em", fontWeight: item.isRefined ? "bold" : "" }}
        onClick={() => {
          refine(item.value);
        }}
        onKeyPress={() => {
          refine(item.value);
        }}
        role="link"
        tabIndex={0}
      >
        <span className="ml-0 mr-2">
          {item.isRefined ? (
            <input checked style={{ cursor: "pointer" }} type="checkbox" />
          ) : (
            <input style={{ cursor: "pointer" }} type="checkbox" />
          )}
        </span>
        {`${item.label} (${item.count})`}
      </Refinement>
    ))}
  </div>
);

const ConnectedRefinementList = connectRefinementList(RefinementList);

RefinementList.propTypes = {
  refine: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      count: PropTypes.number,
      value: PropTypes.array,
      isRefined: PropTypes.bool
    })
  )
};

RefinementList.defaultProps = {
  refine: () => {},
  items: [
    {
      label: "label",
      count: 1,
      value: [1],
      isRefined: true
    }
  ]
};

export { ConnectedRefinementList, RefinementList };
