import React from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import { connectStats } from "react-instantsearch-dom";
import PropTypes from "prop-types";

const Stats = ({ nbHits, processingTimeMS, className }) => {
  const { currentLocale } = intl.getInitOptions();
  const nbHitsLocalized = nbHits.toLocaleString(currentLocale);
  const processingTime = (processingTimeMS / 1000).toLocaleString(
    currentLocale,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  );

  return (
    <span className={className}>
      {intl.get("STATS", { nbHitsLocalized, processingTime })}
    </span>
  );
};

const StyledStats = styled(Stats)`
  font-weight: 100;
  font-size: 0.8em;
  color: #dfdfdf;
`;

Stats.propTypes = {
  nbHits: PropTypes.number,
  processingTimeMS: PropTypes.number,
  className: PropTypes.string
};

Stats.defaultProps = {
  nbHits: 0,
  processingTimeMS: 1,
  className: ""
};

const ConnectedStats = connectStats(StyledStats);

export { Stats, ConnectedStats };
