import runtimeEnv from "@mars/heroku-js-runtime-env";

// fetch runtime environment variables for Heroku
// @link https://github.com/mars/create-react-app-buildpack#runtime-configuration
const env = runtimeEnv();

const {
  REACT_APP_GOLDENGATE_URL_STAGING,
  REACT_APP_GOLDENGATE_URL_PRODUCTION,
  REACT_APP_ALGOLIA_INDEX_NAME_STAGING,
  REACT_APP_ALGOLIA_INDEX_NAME_PRODUCTION,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_GOOGLE_GEOCODING_API_KEY
} = process.env;

const { REACT_APP_ENV, REACT_APP_EMBEDDED } = env;
const IS_EMBEDDED = REACT_APP_EMBEDDED;
const DEFAULT_COMPANIES_IDS = env.REACT_APP_DEFAULT_COMPANIES_IDS || "";
const DEFAULT_BOOKING_SYSTEMS = env.REACT_APP_DEFAULT_BOOKING_SYSTEMS || "";

const GOOGLE_API_KEY = REACT_APP_GOOGLE_MAPS_API_KEY;
const GOOGLE_GEOCODING_API_KEY = REACT_APP_GOOGLE_GEOCODING_API_KEY;

const GOLDENGATE_BASE_URL =
  REACT_APP_ENV === "staging"
    ? REACT_APP_GOLDENGATE_URL_STAGING
    : REACT_APP_GOLDENGATE_URL_PRODUCTION;
const API_BOATS_URL = `${GOLDENGATE_BASE_URL}/boats`;

const ALGOLIA_INDEX_NAME =
  REACT_APP_ENV === "staging"
    ? REACT_APP_ALGOLIA_INDEX_NAME_STAGING
    : REACT_APP_ALGOLIA_INDEX_NAME_PRODUCTION;

const URL_UPDATE_DELAY = 700; // milliseconds
const BOAT_PAGE_PATH = "boats";
const API_QUOTES_URL = `${GOLDENGATE_BASE_URL}/quotes`;
const API_PRICES_URL = `${GOLDENGATE_BASE_URL}/prices`;

const DEFAULT_DURATION_IN_DAYS = 7;

export {
  ALGOLIA_INDEX_NAME,
  API_BOATS_URL,
  API_QUOTES_URL,
  API_PRICES_URL,
  BOAT_PAGE_PATH,
  DEFAULT_BOOKING_SYSTEMS,
  DEFAULT_COMPANIES_IDS,
  GOLDENGATE_BASE_URL,
  GOOGLE_API_KEY,
  IS_EMBEDDED,
  URL_UPDATE_DELAY,
  GOOGLE_GEOCODING_API_KEY,
  DEFAULT_DURATION_IN_DAYS
};
