import React from "react";
import { ConnectedRefinementSelect } from "./ConnectedRefinementSelect";

const lowerCasedAllowedBuilderList = [
  "ad boats",
  "alfa yachts",
  "alliaura",
  "alliaura marine",
  "altamarea",
  "alubat",
  "aquila",
  "archambaut",
  "bali",
  "bavaria",
  "bavaria yachtbau",
  "bavaria yachts",
  "bayliner",
  "benetau",
  "beneteau",
  "blue wtaer marine",
  "boston whaler",
  "bénéteau",
  "caliber",
  "carver",
  "carver yacht",
  "catalina",
  "catamaran",
  "catana",
  "chantier allures yachting",
  "cruisers yachts",
  "custom built",
  "dean catamarans",
  "delphia",
  "dufour",
  "dufour yacht",
  "dufour yachts",
  "elan",
  "elan marine",
  "elan yachts",
  "elite",
  "fairway",
  "fairway marine",
  "fontaine pajot",
  "fountain pajot",
  "fountaine pajot",
  "fountainte pajot",
  "gemini",
  "gemini catamarans",
  "gib sea",
  "grand banks",
  "grand soleil",
  "hanse",
  "hanse yachts",
  "hunter",
  "idb marine",
  "idbmarine",
  "island spirit",
  "janneau",
  "jeanneau",
  "jeannneau",
  "kirie",
  "lagoon",
  "lagoon catamaran",
  "lagoon catamarans",
  "lidgard yacht design",
  "luhr",
  "manta",
  "marsaudon composites",
  "marée haute",
  "menorquin yachts",
  "monk",
  "montebello",
  "nautitech",
  "nautitech catamarans",
  "neel",
  "o'day",
  "ocean yachts",
  "olea composites",
  "outremer yachting",
  "performance cruising",
  "perry",
  "perry power catamaran",
  "pinta 62",
  "poncin yachts",
  "promarine",
  "quicksilver",
  "rm yacht",
  "robertson & caine",
  "rorbertson & caine",
  "scimitar marine",
  "seawind",
  "selene",
  "structures",
  "sunbeam",
  "sundecker",
  "symbol",
  "tasman catamaran",
  "viko yachts",
  "voyage"
];

export default function BoatBuilderFilter() {
  return (
    <ConnectedRefinementSelect
      attribute="builder_name"
      limit="100000"
      isItemDisplayed={({ label }) =>
        lowerCasedAllowedBuilderList.includes(label.toLowerCase())
      }
    />
  );
}
