import React from "react";
import { VirtualRefinementList } from "./VirtualRefinements";
import parseStringListToArray from "../utils/parseStringListToArray";
import { DEFAULT_COMPANIES_IDS } from "../constants";

const defaultCompanyRefinements = parseStringListToArray(DEFAULT_COMPANIES_IDS);

const Companies = () =>
  DEFAULT_COMPANIES_IDS && (
    <VirtualRefinementList
      attribute="company.id"
      defaultRefinement={defaultCompanyRefinements}
    />
  );

export default Companies;
