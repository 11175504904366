import moment from "moment";

const hitToBoat = hit => {
  const {
    name,
    model_name, // eslint-disable-line camelcase
    type,
    lowest_price, // eslint-disable-line camelcase
    prices,
    year,
    objectID,
    image,
    country,
    city,
    _geoloc,
    loa,
    cabins,
    draft,
    wc
  } = hit;

  return {
    name,
    type,
    year,
    imageUrl: image, // rename `imageUrl` since... it's a url (string)
    country,
    city,
    modelName: model_name,
    lowestPrice: lowest_price,
    id: objectID,
    coordinates: _geoloc,
    prices:
      prices &&
      Object.values(prices).map(price => {
        const {
          id,
          from,
          to,
          days,
          /* eslint-disable camelcase */
          discount_price,
          eur,
          usd,
          gbp
        } = price;
        /* eslint-enable camelcase */
        return {
          id,
          from: moment(from),
          to: moment(to),
          days,
          discountPrice: discount_price,
          eur,
          usd,
          gbp
        };
      }),
    loa,
    cabins,
    draft,
    wc
  };
};

export default hitToBoat;
