import React from "react";
import { Input, Label } from "reactstrap";
import intl from "react-intl-universal";
import momentPropTypes from "react-moment-proptypes";
import PropTypes from "prop-types";
import { useStateValue } from "../state/StateProvider";

const DateModalToggleButton = ({ placeholder, value }) => {
  const [{ theme }] = useStateValue();

  return (
    <div>
      <Label
        style={{ color: "#6d7278", fontSize: ".8em" }}
        className="mb-0 mt-2 text-black"
      >
        {intl.get("DATE_MODAL.FROM_LABEL")}
      </Label>
      <Input
        placeholder={placeholder}
        readOnly
        value={value}
        style={{
          border: `2px solid ${theme.primary}`,
          fontSize: "1em",
          borderRadius: "0",
          color: "#1B5679",
          height: "40px",
          backgroundColor: "white"
        }}
      />
    </div>
  );
};

DateModalToggleButton.propTypes = {
  placeholder: PropTypes.oneOfType([
    momentPropTypes.momentObj,
    PropTypes.string
  ]),
  value: PropTypes.oneOfType([momentPropTypes.momentObj, PropTypes.string])
};

DateModalToggleButton.defaultProps = {
  placeholder: "",
  value: ""
};

export default DateModalToggleButton;
