import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import momentPropTypes from "react-moment-proptypes";
import connectHitsWithPrices from "../utils/connectHitsWithPrices";
import BoatCard from "../boat/BoatCard";
import { currentPrice } from "../utils/boat";
import hitToBoat from "../utils/algoliaAdapter";
import { boatUri } from "../utils/boatPage";
import { useStateValue } from "../state/StateProvider";

const Boats = ({ hits, date }) => {
  return (
    <div className="row">
      {hits.map(hit => {
        const boat = hitToBoat(hit);
        const {
          city,
          country,
          imageUrl,
          modelName,
          id,
          year,
          loa,
          cabins,
          draft,
          wc
        } = boat;

        const [{ currency, durationInDays }] = useStateValue();
        const price = currentPrice(boat.prices, date, durationInDays);
        const { from, to } = price;
        const uri = boatUri(id, from);

        return (
          <BoatCard
            city={city}
            country={country}
            imageUrl={imageUrl}
            modelName={modelName}
            uri={uri}
            year={year}
            key={id}
            amount={price[currency.toLowerCase()]}
            from={from}
            to={to}
            loa={loa}
            cabins={cabins}
            draft={draft}
            wc={wc}
            date={date}
          />
        );
      })}
    </div>
  );
};

const ConnectedBoats = connectHitsWithPrices(Boats);

export default ConnectedBoats;

Boats.propTypes = {
  date: momentPropTypes.momentObj,
  hits: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number)
    ])
  ).isRequired
};

Boats.defaultProps = {
  date: moment("2018-01-01")
};
