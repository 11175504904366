import React from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ConnectedPriceRange } from "./PriceRange";
import { ConnectedYearRange } from "./YearRange";
import { ConnectedLengthRange } from "./LengthRange";
import ConnectedCabinsRange from "./CabinsRange";
import ConnectedCapacityRange from "./CapacityRange";
import BoatTypeFilter from "./BoatTypeFilter";
import BoatBuilderFilter from "./BoatBuilderFilter";
import BoatModelFilter from "./BoatModelFilter";
import EquipmentFilter from "./EquipmentFilter";
import CollapsibleFilter from "./CollapsibleFilter";

const StyledH2 = styled.h2`
  font-weight: 600;
  font-size: 0.8em !important;
  color: #6d7278;
`;
const StyledH1 = styled.h1`
  font-weight: 600;
  font-size: 1.2em !important;
  color: #6d7278;
  margin-bottom: 1em;
`;

const Filters = ({ priceRangeFacet, priceRefinement, children }) => (
  <ScrollList>
    <div className="col-sm-12">
      <div className="d-flex justify-content-between">
        <StyledH1>{intl.get("FILTER_MODAL.MORE_FILTERS")}</StyledH1>
        <span>{children}</span>
      </div>
      <StyledH2>{intl.get("PRICE_TITLE")}</StyledH2>
      <ConnectedPriceRange
        attribute={priceRangeFacet}
        defaultRefinement={priceRefinement}
        precision={2}
      />
    </div>
    <div className="col-sm-12 mt-3">
      <StyledH2>{intl.get("YEAR_TITLE")}</StyledH2>
      <ConnectedYearRange attribute="year" />
    </div>
    <div className="col-sm-12 mt-3">
      <StyledH2>{intl.get("CABINS_TITLE")}</StyledH2>
      <ConnectedCabinsRange attribute="cabins" />
    </div>
    <div className="col-sm-12 mt-3">
      <StyledH2>{intl.get("LENGTH_TITLE")}</StyledH2>
      <ConnectedLengthRange attribute="loa" />
    </div>
    <div className="col-sm-12 mt-3">
      <StyledH2>{intl.get("CAPACITY_TITLE")}</StyledH2>
      <ConnectedCapacityRange attribute="capacity" />
    </div>
    <div className="col-sm-12 mt-4">
      <CollapsibleFilter
        title={<StyledH2>{intl.get("BOAT_TYPE_TITLE")}</StyledH2>}
      >
        <BoatTypeFilter />
      </CollapsibleFilter>
    </div>
    <div className="col-sm-12 mt-4">
      <CollapsibleFilter
        title={<StyledH2>{intl.get("BOAT_BUILDER_TITLE")}</StyledH2>}
      >
        <BoatBuilderFilter />
      </CollapsibleFilter>
    </div>
    <div className="col-sm-12 mt-4">
      <CollapsibleFilter
        title={<StyledH2>{intl.get("BOAT_MODEL_TITLE")}</StyledH2>}
      >
        <BoatModelFilter />
      </CollapsibleFilter>
    </div>
    <div className="col-sm-12 mt-4">
      <CollapsibleFilter title={<StyledH2>{intl.get("EQUIPMENTS")}</StyledH2>}>
        <EquipmentFilter />
      </CollapsibleFilter>
    </div>
  </ScrollList>
);

const ScrollList = styled.div`
  overflow: auto;
  border: 1px solid #dfdfdf;
  padding-top: 1em;
  padding-bottom: 1em;
  @media (min-width: 768px) {
    overflow: visible;
  }
`;

Filters.propTypes = {
  priceRangeFacet: PropTypes.string,
  priceRefinement: PropTypes.shape({
    max: PropTypes.number,
    min: PropTypes.number
  }),
  children: PropTypes.node
};

Filters.defaultProps = {
  priceRangeFacet: "",
  priceRefinement: { max: null, min: null },
  children: null
};

export default Filters;
