import styled from "styled-components";
import { Link } from "react-router-dom";

const UnStyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  :hover {
    color: black;
    text-decoration: none;
  }
`;

export default UnStyledLink;
