import React from "react";
import styled from "styled-components";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { useStateValue } from "../state/StateProvider";

const Button = styled.button`
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  cursor: ${props => props.disabled || "pointer"};
  width: 100%;
  color: ${props => props.primary};
  font-weight: bold;
  background-color: white;
  border: none;
`;

const CustomClearRefinements = ({ items, refine }) => {
  const [{ theme }] = useStateValue();

  return (
    <Button
      onClick={() => refine(items)}
      disabled={!items.length}
      primary={theme.primary}
    >
      {intl.get("DATEPICKER.CLEAR_DATES")}
    </Button>
  );
};

const ClearRefinements = connectCurrentRefinements(CustomClearRefinements);

CustomClearRefinements.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      attribute: PropTypes.string,
      count: PropTypes.number,
      value: PropTypes.array,
      isRefined: PropTypes.bool
    })
  ).isRequired,
  refine: PropTypes.func
};

CustomClearRefinements.defaultProps = {
  refine: () => {}
};

export default ClearRefinements;
