import axios from "axios";
import qs from "qs";
import { GOOGLE_GEOCODING_API_KEY } from "../constants";

export default async function reverseGeocode(latlng) {
  const params = {
    latlng,
    key: GOOGLE_GEOCODING_API_KEY
  };
  const querystring = qs.stringify(params);

  const { data } = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?${querystring}&sensor=false`
  );
  return data.results;
}
