/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import styled from "styled-components";
import Equipment from "./Equipment";
import ArrowNext from "../assets/svg/ArrowNext";

export const ExpandButton = styled.button`
  margin-top: 2em;
  background-color: white;
  font-weight: bold;
  font-size: 0.6;
  color: ${props => props.theme.primary || "#ff9d23"};
  border: none;
`;

const Equipments = ({ children, boatId, theme, onNoContentClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const content =
    children && children.length > 0 ? (
      <>
        <div className="row">
          <div className="row col-12">
            {children.map((equipment, index) =>
              index < 4 ? (
                <Equipment
                  equipment={equipment}
                  key={`${boatId}-${equipment.slug}`}
                  theme={theme}
                />
              ) : null
            )}
          </div>
          {!isExpanded && (
            <ExpandButton
              style={{ marginLeft: "auto" }}
              onClick={() => setIsExpanded(!isExpanded)}
              theme={theme}
            >
              {intl.get("EQUIPMENT.SHOW_MORE").toUpperCase()}
              <ArrowNext
                style={{ transform: "rotate(90deg)", marginLeft: ".5em" }}
                primary={theme.primary}
              />
            </ExpandButton>
          )}
        </div>

        <Collapse className="row" isOpen={isExpanded}>
          <div className="row col-12">
            {children.map((equipment, index) =>
              index >= 4 ? (
                <Equipment
                  equipment={equipment}
                  key={`${boatId}-${equipment.slug}`}
                  theme={theme}
                />
              ) : null
            )}
          </div>
          {isExpanded && (
            <ExpandButton
              style={{ marginLeft: "auto" }}
              onClick={() => setIsExpanded(!isExpanded)}
              theme={theme}
            >
              {intl.get("EQUIPMENT.SHOW_LESS").toUpperCase()}
              <ArrowNext
                style={{ transform: "rotate(-90deg)", marginLeft: ".5em" }}
                primary={theme.primary}
              />
            </ExpandButton>
          )}
        </Collapse>
      </>
    ) : (
      <div>
        {intl.get("EQUIPMENT.NO_CONTENT.PREFIX")}
        <a href="#" onClick={onNoContentClick}>
          {intl.get("EQUIPMENT.NO_CONTENT.LINK")}
        </a>
        {intl.get("EQUIPMENT.NO_CONTENT.SUFFIX")}
      </div>
    );

  return (
    <div>
      <div className="font-weight-bold">{intl.get("EQUIPMENTS")}</div>
      {content}
    </div>
  );
};

Equipments.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({ slug: PropTypes.string })),
  boatId: PropTypes.string,
  theme: PropTypes.shape({ primary: PropTypes.string }).isRequired,
  onNoContentClick: PropTypes.func.isRequired
};

Equipments.defaultProps = {
  children: [{ slug: "gps" }],
  boatId: "12345"
};

export default Equipments;
