import React from "react";
import PropTypes from "prop-types";

const Shower = ({ primary, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-708-589H316v958H-708z" />
      <path fill="#FFF" stroke="#DFDFDF" d="M-185.5-270.5h229v319h-229z" />
      <path
        fill={primary || "#FF9D23"}
        d="M8.834 6.424c2.03 0 3.689 1.543 3.91 3.515h.924v.88H4v-.88h.923c.222-1.972 1.88-3.515 3.911-3.515zm-3.955 5.303h.879v.878h-.88v-.878zm0 1.757h.879v.88h-.88v-.88zm0 1.758h.879v.88h-.88v-.88zm1.758-2.637h.879v.88h-.88v-.88zm0 1.758h.879v.88h-.88v-.88zm0 1.758h.879V17h-.88v-.879zm1.758-4.394h.878v.878h-.878v-.878zm0 1.757h.878v.88h-.878v-.88zm0 1.758h.878v.88h-.878v-.88zm1.757-2.637h.88v.88h-.88v-.88zm0 1.758h.88v.88h-.88v-.88zm0 1.758h.88V17h-.88v-.879zm1.758-4.394h.88v.878h-.88v-.878zm0 1.757h.88v.88h-.88v-.88zm0 1.758h.88v.88h-.88v-.88zM14.547 2h2.637v5.303h-2.637V3.758H9.273v.879h.88v.908H7.515v-.908h.879V2.879h6.152V2z"
      />
    </g>
  </svg>
);

Shower.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Shower.defaultProps = {
  primary: "",
  width: "",
  height: ""
};

export default Shower;
