import moment from "moment";

const boatFromApi = apiBoat => {
  if (!apiBoat) return null;

  const boat = Object.assign({}, apiBoat);

  boat.prices =
    apiBoat.prices &&
    apiBoat.prices.map(price => ({
      ...price,
      from: moment(price.from),
      to: moment(price.to)
    }));

  return boat;
};

const quoteToApi = quoteParams => {
  const {
    to,
    skipper,
    price,
    phone,
    message,
    lastName,
    from,
    firstName,
    email,
    boatId,
    partnerMail,
    site,
    locale
  } = quoteParams;

  return {
    data: {
      type: "quote",
      attributes: {
        from,
        to,
        skipper,
        message,
        price,
        email,
        first_name: firstName,
        last_name: lastName,
        phone,
        partner_mail: partnerMail,
        site,
        locale
      },
      relationships: {
        boat: {
          data: {
            id: boatId
          },
          type: "boat"
        }
      }
    }
  };
};

export { boatFromApi, quoteToApi };
