import axios from "axios";
import { API_QUOTES_URL } from "../constants";

function postQuote(payload) {
  axios
    .post(API_QUOTES_URL, payload)
    .then(() => {
      this.onSucccess();
    })
    .catch(error => {
      if (error.response) {
        const { status, data } = error.response;
        const errorMessage = data.error;
        if (status >= 400 && status < 500) {
          this.setState({ response: { error: true, message: errorMessage } });
        } else {
          this.setState({
            response: {
              error: true,
              message: "An error occurred, please try again later"
            }
          });
        }
      } else {
        this.setState({
          response: {
            error: true,
            message: "An error occurred, please try again later"
          }
        });
      }
    });
}

export default postQuote;
