import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import PropTypes from "prop-types";
import ArrowNext from "../assets/svg/ArrowNext";
import BoatImagePlaceholder from "../boat/BoatImagePlaceholder";
import "./BoatPageSlider.css";
import BoatImage from "../utils/BoatImage";

const StyledImage = styled(BoatImage)`
  height: 360px;
  width: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    height: 260px;
  }
  @media (max-width: 576px) {
    height: 200px;
  }
`;

const Container = styled.div`
  &:before {
    display: none;
  }
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.59);
  background-color: white !important;
  opacity: 1 !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 50;
`;

// eslint-disable-next-line react/prop-types
const Arrow = ({ currentSlide, slideCount, theme, rotate, ...arrowProps }) => (
  <Container {...arrowProps} role="presentation">
    <ArrowNext primary={theme.primary} style={{ transform: rotate }} />
  </Container>
);

const BoatPageSlider = ({ images, theme }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <Arrow theme={theme} />,
    prevArrow: <Arrow theme={theme} rotate="rotate(180deg)" />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false
        }
      }
    ]
  };

  return images.length ? (
    <div style={{ marginTop: "-60px" }}>
      <Slider {...settings}>
        {images.map(img => (
          <div key={img.url}>
            <StyledImage url={img.url} alt="boatImage" />
          </div>
        ))}
      </Slider>
    </div>
  ) : (
    <div style={{ marginTop: "-60px", height: "300px" }}>
      <BoatImagePlaceholder />
    </div>
  );
};

BoatPageSlider.propTypes = {
  images: PropTypes.node.isRequired,
  theme: PropTypes.shape({ primary: PropTypes.string }).isRequired
};

export default BoatPageSlider;
