import React from "react";
import PropTypes from "prop-types";

const Check = ({ primary, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 20}
    height={height || 20}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" stroke="#DFDFDF" d="M-121.5-383.5h716v1419h-716z" />
      <text
        fill="#6D7278"
        fontFamily="Lato-Regular, Lato"
        fontSize="12"
        transform="translate(1)"
      >
        <tspan x="25" y="12">
          GPS
        </tspan>
      </text>
      <g transform="translate(2 2)">
        <path
          fill={primary || "#FF8D02"}
          d="M5.068 7.94L8.998 4l.526.526-4.456 4.456L3 6.914l.517-.525z"
        />
        <circle cx="6" cy="6" r="6" stroke={primary || "#FF9D23"} />
      </g>
    </g>
  </svg>
);

Check.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Check.defaultProps = {
  primary: "",
  width: "",
  height: ""
};

export default Check;
