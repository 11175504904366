import React from "react";
import PropTypes from "prop-types";
import markerStyle from "../utils/markerStyle";

const Marker = ({ color }) => (
  /*  eslint-disable max-len */
  <svg style={markerStyle} viewBox="0 0 200 200" className="marker">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-60, 0)" id="pin" viewBox="0 0 100 100">
        <path
          d="M157.39 34.315c0 18.546-33.825 83.958-33.825 83.958S89.74 52.86 89.74 34.315C89.74 15.768 104.885.73 123.565.73c18.68 0 33.825 15.038 33.825 33.585z"
          strokeWidth="5.53"
          fill={color || "#F88540"}
        />
        <path
          d="M123.565 49.13c-8.008 0-14.496-6.498-14.496-14.52 0-8.017 6.487-14.52 14.495-14.52s14.496 6.503 14.496 14.52c0 8.022-6.487 14.52-14.495 14.52z"
          strokeWidth="2.765"
          fill="#FFF"
        />
      </g>
    </g>
  </svg>
);

Marker.propTypes = {
  color: PropTypes.string
};

Marker.defaultProps = {
  color: "#F88540"
};

/*  eslint-enable max-len */

export default Marker;
