const checkIfShouldRefine = (
  currentRefinement,
  maxRange,
  minRange,
  refine,
  sliderState
) => {
  const newRefinement = {
    min: sliderState.values[0],
    max: sliderState.values[1]
  };

  if (
    currentRefinement.min !== newRefinement.min ||
    currentRefinement.max !== newRefinement.max
  ) {
    if (newRefinement.max === maxRange) delete newRefinement.max;
    if (newRefinement.min === minRange) delete newRefinement.min;
    refine(newRefinement);
  }
};

export default checkIfShouldRefine;
