import { formatDateISO } from "./date";

function formatPriceRangeFacet(startDate, durationInDays) {
  return startDate
    ? `prices.${formatDateISO(startDate)}|${durationInDays}.discount_price`
    : "lowest_price";
}

const generateSnapPoints = (minRange, maxRange, snapPointsInterval) =>
  Array.from(
    new Array((maxRange - minRange) / snapPointsInterval + 1),
    (val, index) => index * snapPointsInterval + minRange
  );

export { formatPriceRangeFacet, generateSnapPoints };
