import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import intl from "react-intl-universal";

const BackButton = styled.button`
  z-index: 2;
  background: none;
  color: white;
  border: none;
  font-weight: 600;
  padding: 0;
`;

const GoBack = ({ history }) => (
  <BackButton
    onClick={() => history.goBack()}
    onKeyPress={() => history.goBack()}
  >
    <FaChevronLeft />
    {intl.get("BACK_BUTTON")}
  </BackButton>
);

const Back = withRouter(GoBack);

GoBack.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired })
};

GoBack.defaultProps = {
  history: { goBack: () => {} }
};

export default Back;
