import { fitBounds } from "google-map-react/utils";

const size = {
  width: 220, // Map width in pixels
  height: 220 // Map height in pixels
};

const setCenterAndZoom = points => {
  const isSamePoint =
    points.nw.lat === points.se.lat && points.nw.lng === points.se.lng;
  if (isSamePoint) {
    return {
      center: points.nw,
      zoom: 11
    };
  }
  return fitBounds(points, size);
};

const boundingPoints = boats =>
  boats.reduce(
    (bounds, boat) => {
      const pos = boat.coordinates;
      const newBounds = bounds;
      if (pos.lat > bounds.nw.lat) newBounds.nw.lat = pos.lat;
      if (pos.lat < bounds.se.lat) newBounds.se.lat = pos.lat;

      if (pos.lng < bounds.nw.lng) newBounds.nw.lng = pos.lng;
      if (pos.lng > bounds.se.lng) newBounds.se.lng = pos.lng;
      return newBounds;
    },
    {
      nw: { lat: -85, lng: 180 },
      se: { lat: 85, lng: -180 }
    }
  );

export { setCenterAndZoom, boundingPoints };
