import styled from "styled-components";
import Price from "../boat/Price";

const CardPrice = styled(Price)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.primary};
  top: 0;
  width: 100%;
  line-height: 2.5rem;

  font-weight: 400;
  font-size: 1.2rem;
  color: white;
  text-align: center;
  @media (min-width: 768px) {
    text-align: ${props => props.textAlignDesktop};
  }
`;

export default CardPrice;
