import moment from "moment";

function formatDateForFilters(date, durationInDays) {
  const utcOffset = date.utcOffset();
  const newDate = date
    .clone()
    .startOf("day")
    .add(utcOffset, "minutes")
    .isoWeekday(6)
    .format("YYYY-MM-DD");
  return `${newDate}|${durationInDays}`;
}

function parseDateFromFilters(filters) {
  const fromRegex = /dates=(\d*)/;
  return fromRegex.exec(filters) && moment.unix(fromRegex.exec(filters)[1]);
}

function parseDateISO(dateString) {
  const momentDate = moment(dateString, "YYYY-MM-DD", true);
  if (!momentDate.isValid()) return null;
  return momentDate;
}

const formatDateISO = date => date && date.format("YYYY-MM-DD");

function prevSaturday(date) {
  if (date.isoWeekday() === 6 || date.isoWeekday() === 7)
    return date.clone().isoWeekday(6);
  return date.clone().isoWeekday(-1);
}

function nextSaturday(date) {
  if (date.isoWeekday() === 6 || date.isoWeekday() === 7)
    return date.clone().isoWeekday(13);
  return date.clone().isoWeekday(6);
}

const isSameSaturdayWeek = (date1, date2) =>
  prevSaturday(date1).isSame(prevSaturday(date2), "day");

const isSameDay = (date1, date2) => moment(date1).isSame(moment(date2), "day");

const isSameMonth = (date1, date2) =>
  moment(date1).isSame(moment(date2), "month");

const isSameYear = (date1, date2) =>
  moment(date1).isSame(moment(date2), "year");

export {
  formatDateForFilters,
  parseDateFromFilters,
  parseDateISO,
  formatDateISO,
  prevSaturday,
  nextSaturday,
  isSameSaturdayWeek,
  isSameDay,
  isSameMonth,
  isSameYear
};
