import React, { Component } from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import {
  buildHandleSubmit,
  handleChange
} from "../utils/contactFormController";
import ContactForm from "./ContactForm";
import StateContext from "../state/context";
import determineLocale from "../utils/determineLocale";

class ContactFormController extends Component {
  static contextType = StateContext;

  constructor() {
    super();
    this.state = {
      response: {
        error: false,
        message: null
      }
    };

    this.handleChange = handleChange.bind(this);
    this.buildHandleSubmit = buildHandleSubmit.bind(this);
    this.onSucccess = this.onSucccess.bind(this);
  }

  onSucccess() {
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = this.context;
    dispatch({
      type: "message",
      message: intl.get("QUOTE.SUCCESS")
    });
    this.props.toggle();
  }

  render() {
    const [{ mail, site }] = this.context;
    const quoteParams = {
      ...this.props,
      ...this.state,
      partnerMail: mail,
      site,
      locale: determineLocale()
    };
    const handleSubmit = this.buildHandleSubmit(quoteParams);
    const { error, message } = this.state.response;
    const status = error ? "danger" : "success";
    const { submitId } = this.props;

    return (
      <div>
        <ContactForm
          handleChange={this.handleChange}
          handleSubmit={handleSubmit}
          status={status}
          message={message}
          submitId={submitId}
        />
      </div>
    );
  }
}

ContactFormController.propTypes = {
  toggle: PropTypes.func,
  submitId: PropTypes.string
};

ContactFormController.defaultProps = {
  toggle: () => {},
  submitId: ""
};

export default ContactFormController;
