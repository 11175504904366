import React from "react";
import intl from "react-intl-universal";
import humanizeString from "humanize-string";
import CheckboxesFilter from "./CheckboxesFilter";

const filterableEquipments = [
  "air_conditioning",
  "autopilot",
  "batten_mainsail",
  "dinghy",
  "electric_winch",
  "furling_mainsail",
  "generator",
  "gps",
  "wifi"
];

function EquipmentFilter() {
  return (
    <CheckboxesFilter
      attribute="equipment"
      operator="and"
      isItemDisplayed={({ label }) => filterableEquipments.includes(label)}
      translateItem={({ label }) =>
        intl
          .get(`EQUIPMENT.${label.toUpperCase()}`)
          .defaultMessage(humanizeString(label))
      }
    />
  );
}

export default EquipmentFilter;
