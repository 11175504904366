import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
`;

const Value = styled.span`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: #6d7278;
`;
const ModelInfo = ({ value, unit, icon }) => {
  if (!value) return null;

  return (
    <Container className="px-1">
      {icon}
      <Value>{`${value} ${unit}`}</Value>
    </Container>
  );
};

ModelInfo.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.string,
  icon: PropTypes.node
};

ModelInfo.defaultProps = {
  value: 40,
  unit: "feet",
  icon: <span />
};

export default ModelInfo;
