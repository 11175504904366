import React from "react";
import PropTypes from "prop-types";
import ImgixImage from "./ImgixImage";
import BoatImagePlaceholder from "../boat/BoatImagePlaceholder";

const BoatImage = ({ url, className }) =>
  url ? (
    <ImgixImage url={url} className={className} />
  ) : (
    <BoatImagePlaceholder className={className} />
  );

BoatImage.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string
};

BoatImage.defaultProps = {
  url: "",
  className: ""
};
export default BoatImage;
