import React from "react";
import { VirtualRefinementList } from "./VirtualRefinements";
import parseStringListToArray from "../utils/parseStringListToArray";
import { DEFAULT_BOOKING_SYSTEMS } from "../constants";

const defaultBookingSystemRefinements = parseStringListToArray(
  DEFAULT_BOOKING_SYSTEMS
);

const BookingSystems = () =>
  DEFAULT_BOOKING_SYSTEMS && (
    <VirtualRefinementList
      attribute="booking_system.name"
      defaultRefinement={defaultBookingSystemRefinements}
    />
  );

export default BookingSystems;
