import React from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { useStateValue } from "../state/StateProvider";

const duration = 500;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
  height: 0
};

const transitionStyles = {
  entering: { opacity: 0, height: 0 },
  entered: { opacity: 1, height: "50px" },
  exiting: { opacity: 0, height: 0 }
};

const StyledMessage = styled.div`
  width: 25%;
  background-color: lightgreen;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  padding: 0.5em;
  border-radius: 25px;
  align-items: center;
`;

const Message = () => {
  const [{ message }, dispatch] = useStateValue();
  if (message) {
    setTimeout(() => {
      dispatch({
        type: "message",
        message: false
      });
    }, 5000);
  }
  return (
    <Transition in={!!message} timeout={duration}>
      {state => (
        <StyledMessage
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          {message}
        </StyledMessage>
      )}
    </Transition>
  );
};

export default Message;
