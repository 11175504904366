import React from "react";
import { connectHits } from "react-instantsearch-dom";
import PropTypes from "prop-types";
import Map from "./Map";
import hitToBoat from "../utils/algoliaAdapter";
import { setCenterAndZoom, boundingPoints } from "../utils/map";
import Marker from "./Marker";
import { useStateValue } from "../state/StateProvider";

const MapWithMarkers = ({ hits }) => {
  const [{ theme }] = useStateValue();
  if (!hits.length) return null;
  const boats = hits.map(hit => hitToBoat(hit));

  const markers = boats.map(boat => (
    <Marker
      lat={boat.coordinates.lat}
      lng={boat.coordinates.lng}
      key={boat.id}
      color={theme.primary}
    />
  ));

  const { center, zoom } = setCenterAndZoom(boundingPoints(boats));

  return (
    <Map center={center} zoom={zoom}>
      {markers}
    </Map>
  );
};

MapWithMarkers.propTypes = {
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      objectID: PropTypes.string,
      _geoloc: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
      })
    })
  )
};

MapWithMarkers.defaultProps = {
  hits: [
    {
      objectID: "1",
      _geoloc: { lat: 42.36, lng: -71.05 }
    }
  ]
};

const ConnectedMap = connectHits(MapWithMarkers);

export { MapWithMarkers, ConnectedMap };
