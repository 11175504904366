import normalize from "json-api-normalizer";
import build from "redux-object";
import moment from "moment";
import fetchBoat from "./fetchBoat";
import { boatFromApi } from "../utils/apiAdapter";

const buildBoat = (boat, boatId) => {
  if (!boat) return null;
  return build(normalize(boat), "boat", boatId);
};

const getBoat = boatId =>
  fetchBoat(boatId).then(boat => boatFromApi(buildBoat(boat, boatId)));

const formatPricePeriods = pricePeriods =>
  pricePeriods.map(pricePeriod => {
    const from = moment(pricePeriod.from);
    const to = moment(pricePeriod.to);
    const { price, id } = pricePeriod;

    return { from, to, price, id };
  });

const formatPrices = prices =>
  prices.map(price => {
    const { id, from, to, days, discountPrice, eur, usd, gbp } = price;
    return {
      id,
      from: moment(from),
      to: moment(to),
      days,
      discountPrice,
      eur,
      usd,
      gbp
    };
  });

export { getBoat, formatPricePeriods, formatPrices };
