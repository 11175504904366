import React from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import styled from "styled-components";
import ModelInfo from "./BoatPageModelInfo";
import LoaIcon from "../assets/svg/Loa";
import Cabins from "../assets/svg/Cabin";
import Year from "../assets/svg/Year";
import Shower from "../assets/svg/Shower";
import Capacity from "../assets/svg/Capacity";

const StyledBoatPageModelInfos = styled.span`
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  flex-wrap: wrap;
`;

const BoatPageModelInfos = ({ loa, cabins, wc, year, capacity, theme }) => (
  <StyledBoatPageModelInfos>
    {loa ? (
      <ModelInfo
        value={Math.round(loa)}
        unit={intl.get("LOA")}
        unit2={intl.get("LOA_UNIT")}
        icon={<LoaIcon height="3em" width="3em" primary={theme.primary} />}
      />
    ) : null}
    {year ? (
      <ModelInfo
        value={year}
        unit={intl.get("YEARS_UNIT", { num: year })}
        icon={<Year height="3em" width="3em" primary={theme.primary} />}
      />
    ) : null}
    {cabins ? (
      <ModelInfo
        value={cabins}
        unit={intl.get("CABINS_UNIT", { num: cabins })}
        icon={<Cabins height="3em" width="3em" primary={theme.primary} />}
      />
    ) : null}
    {capacity ? (
      <ModelInfo
        value={capacity}
        unit={intl.get("CAPACITY_DESCRIPTION")}
        icon={<Capacity height="3em" width="3em" primary={theme.primary} />}
      />
    ) : null}
    {wc ? (
      <ModelInfo
        value={wc}
        unit={intl.get("WC_DESCRIPTION", { num: wc })}
        icon={<Shower height="3em" width="3em" primary={theme.primary} />}
      />
    ) : null}
  </StyledBoatPageModelInfos>
);

BoatPageModelInfos.propTypes = {
  loa: PropTypes.number,
  cabins: PropTypes.number,
  wc: PropTypes.number,
  year: PropTypes.number,
  capacity: PropTypes.number,
  theme: PropTypes.shape({ primary: PropTypes.string }).isRequired
};

BoatPageModelInfos.defaultProps = {
  loa: null,
  cabins: null,
  wc: null,
  year: null,
  capacity: null
};

export default BoatPageModelInfos;
