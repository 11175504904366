import React from "react";
import PropTypes from "prop-types";
import { connectRange } from "react-instantsearch-dom";
import Rheostat from "rheostat";
import "rheostat/css/rheostat.css";
import "rheostat/initialize";
import styled from "styled-components";
import DisplayValues from "./DisplayValues";
import "./Range.css";
import checkIfShouldRefine from "../utils/checkIfShouldRefine";
import { useStateValue } from "../state/StateProvider";

const ProgressBar = styled.div`
  left: 5.27502%;
  width: 94.725%;
  height: 2px;
  top: 5px;
  background-color: ${props => props.primary};
  position: absolute;
`;

const MyProgressBar = ({ ...props }) => {
  const [{ theme }] = useStateValue();
  return (
    <ProgressBar
      {...props}
      aria-labelledby="alabelId"
      primary={theme.primary}
    />
  );
};

const StyledHandle = styled.button`
  z-index: 1;
  margin-top: 2px;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: ${props => props.primary};
  border-width: 0;
  padding: 0;
`;

const MyHandle = ({ ...props }) => {
  const [{ theme }] = useStateValue();
  return (
    <StyledHandle
      {...props}
      type="button"
      aria-labelledby="alabelId"
      primary={theme.primary}
    />
  );
};

const Range = ({
  displayComponent,
  maxRange,
  minRange,
  snapPoints,
  algorithm,
  min,
  max,
  currentRefinement,
  refine,
  suffix
}) => {
  const minBound = Math.max(min, minRange);
  const maxBound = Math.min(max, maxRange);

  let minValue =
    currentRefinement.min >= minBound && currentRefinement.min <= maxBound
      ? currentRefinement.min
      : minBound;
  let maxValue =
    currentRefinement.max >= minBound && currentRefinement.max <= maxBound
      ? currentRefinement.max
      : maxBound;
  minValue = Math.min(minValue, maxValue);
  maxValue = Math.max(minValue, maxValue);

  const onChange = sliderState =>
    checkIfShouldRefine(
      currentRefinement,
      maxBound,
      minBound,
      refine,
      sliderState
    );

  return (
    <div>
      <Rheostat
        min={minBound}
        max={maxBound}
        values={[minValue, maxValue]}
        algorithm={algorithm}
        snap
        disabled={min === max}
        snapPoints={snapPoints}
        onChange={onChange}
        progressBar={MyProgressBar}
        handle={MyHandle}
      />
      <DisplayValues
        displayComponent={displayComponent}
        min={minValue}
        max={maxValue}
        suffix={suffix}
      />
    </div>
  );
};

Range.propTypes = {
  minRange: PropTypes.number,
  maxRange: PropTypes.number,
  snapPoints: PropTypes.arrayOf(PropTypes.number),
  algorithm: PropTypes.objectOf(PropTypes.func),
  min: PropTypes.number,
  max: PropTypes.number,
  currentRefinement: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  refine: PropTypes.func,
  displayComponent: PropTypes.node,
  suffix: PropTypes.string
};

Range.defaultProps = {
  minRange: null,
  maxRange: null,
  algorithm: { getPosition: () => {} },
  snapPoints: [1, 2, 3],
  min: 0,
  max: 100,
  currentRefinement: { min: 0, max: 100 },
  refine: () => {},
  displayComponent: <DisplayValues />,
  suffix: ""
};

const ConnectedRange = connectRange(Range);

export { ConnectedRange, Range };
