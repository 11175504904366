import qs from "qs";
import { nextSaturday, parseDateFromFilters } from "../utils/date";

// when a click on the map is performed, the query is reset and the aroundLatLng property updated.
function onLatLngChange({ lat, lng }) {
  this.setState(prevState => ({
    searchState: {
      ...prevState.searchState,
      page: 1,
      query: ""
    },
    aroundLatLng: `${lat},${lng}`
  }));
}

function searchStateToUrl(searchState) {
  return searchState
    ? `${window.location.pathname}?${qs.stringify(searchState)}`
    : "";
}

const setInitialHistory = searchState => {
  window.history.replaceState(searchState, null, null);
};

const aroundLatLngFromSearchState = searchState => {
  const defaultLatLng = {
    lat: 43.29,
    lng: 5.36
  };

  return searchState && searchState.configure
    ? searchState.configure.aroundLatLng
    : `${defaultLatLng.lat},${defaultLatLng.lng}`;
};

const startDateFromSearchState = searchState =>
  searchState && searchState.configure && searchState.configure.filters
    ? parseDateFromFilters(searchState.configure.filters)
    : null;

const endDateFromStartDate = startDate =>
  startDate ? nextSaturday(startDate) : null;

const priceRangeCurrentRefinement = searchState => {
  if (!searchState.range) return null;
  const { range } = searchState;

  const priceRangeFacet = Object.keys(range).find(facet =>
    facet.match(/price/)
  );
  if (!priceRangeFacet) return null;

  return range[priceRangeFacet];
};

export {
  onLatLngChange,
  setInitialHistory,
  startDateFromSearchState,
  endDateFromStartDate,
  aroundLatLngFromSearchState,
  searchStateToUrl,
  priceRangeCurrentRefinement
};
