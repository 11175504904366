import React from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import Filters from "../assets/svg/Filters";

const FilterModalToggleButton = ({ theme }) => (
  <div
    style={{ color: "#6d7278", fontWeight: "bold", lineHeight: ".5" }}
    className="text-black text-left mt-3"
  >
    <span style={{ cursor: "pointer", marginRight: ".5em" }}>
      <Filters primary={theme.primary} />
    </span>
    {intl.get("FILTER_MODAL.MORE_FILTERS").toUpperCase()}
  </div>
);

FilterModalToggleButton.propTypes = {
  theme: PropTypes.shape({ primary: PropTypes.string })
};
FilterModalToggleButton.defaultProps = {
  theme: { primary: "#FF9D23" }
};

export default FilterModalToggleButton;
