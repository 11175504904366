import React, { Component } from "react";
import { InstantSearch, Configure, Pagination } from "react-instantsearch-dom";
import "instantsearch.css/themes/algolia.css";
import qs from "qs";
import { START_DATE } from "react-dates/constants";
import { ALGOLIA_INDEX_NAME, URL_UPDATE_DELAY } from "../constants";
import Boats from "../search/Boats";
import "./Search.css";
import {
  onLatLngChange,
  setInitialHistory,
  startDateFromSearchState,
  endDateFromStartDate,
  aroundLatLngFromSearchState,
  searchStateToUrl,
  priceRangeCurrentRefinement
} from "../utils/search";
import { formatDateForFilters } from "../utils/date";
import { onFocusChange } from "../utils/datepicker";
import { formatPriceRangeFacet } from "../utils/range";
import Companies from "./Companies";
import BookingSystems from "./BookingSystems";
import Header from "./Header";
import Filters from "./Filters";
import VirtualFilters from "./VirtualFilters";
import ClearRefinements from "./ClearRefinements";
import Message from "./Message";
import StateContext from "../state/context";

const mediaQueryList = window.matchMedia("(min-width: 720px)");

class Search extends Component {
  static contextType = StateContext;

  constructor() {
    super();

    const searchState = qs.parse(window.location.search.slice(1)) || {};

    const startDate = startDateFromSearchState(searchState);
    const endDate = endDateFromStartDate(startDate);
    const aroundLatLng = aroundLatLngFromSearchState(searchState);

    this.state = {
      searchState,
      aroundLatLng,
      startDate,
      endDate,
      focusedInput: START_DATE,
      isDesktop: false,
      priceRefinement: null
    };

    this.onSearchStateChange = this.onSearchStateChange.bind(this);
    this.onLatLngChange = onLatLngChange.bind(this);
    this.onSuggestSelect = this.onSuggestSelect.bind(this);
    this.onFocusChange = onFocusChange.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.onClearDates = this.onClearDates.bind(this);

    this.onPopState = this.onPopState.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = this.context;

    window.iFrameResizer = {
      onMessage(message) {
        const { mail, countries, site, group, poweredByLogo } = message;
        const theme = JSON.parse(message.theme);
        dispatch({
          type: "iframeConfig",
          mail,
          countries,
          theme,
          site,
          group,
          poweredByLogo
        });
      }
    };

    this.updateWindowWidth();
    mediaQueryList.addListener(this.updateWindowWidth);
    setInitialHistory(this.state.searchState);

    window.addEventListener("popstate", this.onPopState);
  }

  componentWillUnmount() {
    mediaQueryList.removeListener(this.updateWindowWidth);
    window.removeEventListener("popstate", this.onPopState);
  }

  onPopState({ state: searchState }) {
    const startDate = startDateFromSearchState(searchState);
    const endDate = endDateFromStartDate(startDate);

    this.setState({
      searchState,
      startDate,
      endDate
    });
  }

  onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
  }

  onClearDates() {
    this.setState({ startDate: null, endDate: null });
  }

  onSuggestSelect(suggest) {
    const latLng =
      suggest && suggest.location ? suggest.location : { lat: "", lng: "" };
    this.onLatLngChange(latLng);
  }

  onSearchStateChange(newSearchState) {
    const mergedSearchState = {
      ...this.state.searchState,
      ...newSearchState
    };

    const priceRefinement =
      priceRangeCurrentRefinement(newSearchState) ||
      priceRangeCurrentRefinement(this.state.searchState);

    this.setState({
      searchState: mergedSearchState,
      priceRefinement
    });

    // update the URL when there is a new search state.
    // the debouncedSetState property is used to get a reference to the timeout
    // so it can be cleared
    clearTimeout(this.debouncedSetState);
    this.debouncedSetState = setTimeout(() => {
      window.history.pushState(
        mergedSearchState,
        null,
        searchStateToUrl(mergedSearchState)
      );
    }, URL_UPDATE_DELAY);
  }

  filters() {
    const [{ countries, durationInDays }] = this.context;
    const countryList = countries && countries.split(",");
    const countryListString = countryList && countryList.join(" OR country:");
    const countryFilter = countryListString
      ? ` AND country: ${countryListString}`
      : "";
    const defaultFilters = `type: 'sailboat' OR type: 'catamaran' OR type: 'gulet' OR type: 'yacht'${countryFilter}`;
    if (!this.state.startDate) return `${defaultFilters}`;
    const dates = formatDateForFilters(this.state.startDate, durationInDays);

    return `dates:${dates} AND ${defaultFilters}`;
  }

  updateWindowWidth() {
    const isDesktop = mediaQueryList.matches;
    this.setState({ isDesktop });
  }

  render() {
    const configuration = (
      <Configure
        aroundLatLng={this.state.aroundLatLng}
        filters={this.filters()}
      />
    );

    const [{ durationInDays }] = this.context;
    const priceRangeFacet = formatPriceRangeFacet(
      this.state.startDate,
      durationInDays
    );

    return (
      <InstantSearch
        appId="EWU3TP73NC"
        apiKey="fe6e2ea027bd46275d31121e1e1b83f3"
        indexName={ALGOLIA_INDEX_NAME}
        searchState={this.state.searchState}
        onSearchStateChange={this.onSearchStateChange}
      >
        <div className="container-fluid" style={{ maxWidth: "97%" }}>
          <VirtualFilters
            priceRangeFacet={priceRangeFacet}
            priceRefinement={this.state.priceRefinement}
          />
          {configuration}
          <BookingSystems />
          <Companies />
          <Header
            onSuggestSelect={this.onSuggestSelect}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            isDesktop={this.state.isDesktop}
            focusedInput={this.state.focusedInput}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            onClearDates={this.onClearDates}
            priceRefinement={this.state.priceRefinement}
          />

          <Message />

          <div className="row">
            {this.state.isDesktop && (
              <div
                style={{ height: "100%", zIndex: "1" }}
                className="d-none d-lg-block col-lg-4 col-sm-12 sticky-top"
              >
                <Filters
                  priceRangeFacet={priceRangeFacet}
                  priceRefinement={this.state.priceRefinement}
                >
                  <ClearRefinements />
                </Filters>
              </div>
            )}
            <div className="col-lg-8 col-sm-12">
              <Boats date={this.state.startDate} />
            </div>
            <div className="col-6 offset-3 p-3">
              <Pagination className="col-6 offset-3 p-3" />
            </div>
          </div>
        </div>
      </InstantSearch>
    );
  }
}

export default Search;
