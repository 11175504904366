import React from "react";
import PropTypes from "prop-types";

const Capacity = ({ primary, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "32"}
    height={height || "32"}
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" stroke="#DFDFDF" d="M-439.5-222.5h716v1419h-716z" />
      <path
        fill={primary || "#FF8D02"}
        stroke="#FFF"
        d="M22.833 22.353c.34.27.535.678.535 1.104v2.017H8.632v-2.017c0-.426.195-.835.535-1.104 1.878-1.496 3.899-2.493 4.314-2.689a.13.13 0 0 0 .074-.118v-2.84a1.311 1.311 0 0 1-.61-1.104v-2.946c0-1.468 1.22-2.656 2.73-2.656h.65c1.51 0 2.73 1.188 2.73 2.656v2.946c0 .46-.242.863-.61 1.104v2.84c0 .05.028.096.074.118.415.196 2.436 1.188 4.314 2.69zm6.693-1.763c.293.229.456.578.474.944v1.73h-5.679a2.559 2.559 0 0 0-.913-1.5c-1.364-1.1-2.81-1.947-3.724-2.44a19.369 19.369 0 0 1 1.88-1.041c.04-.023.064-.057.064-.103v-2.433a1.133 1.133 0 0 1-.521-.95v-2.524c0-1.254 1.042-2.273 2.324-2.273h.55c1.283 0 2.325 1.019 2.325 2.273v2.524c0 .4-.211.744-.521.95v2.433c0 .046.023.086.064.103.351.166 2.078 1.019 3.677 2.307zM10.43 18.283c.216.103.966.47 1.885 1.042-.914.492-2.36 1.34-3.724 2.438a2.53 2.53 0 0 0-.913 1.5H2v-1.729c0-.366.17-.71.457-.944 1.598-1.288 3.325-2.141 3.676-2.307.041-.023.065-.057.065-.103v-2.433a1.133 1.133 0 0 1-.521-.95v-2.524C5.677 11.019 6.719 10 8 10h.562c1.282 0 2.324 1.019 2.324 2.273v2.524c0 .4-.21.744-.52.95v2.433c0 .046.023.086.064.103z"
      />
      <path stroke="#DFDFDF" strokeOpacity=".607" d="M-23.5-15.5h79v79h-79z" />
    </g>
  </svg>
);

Capacity.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Capacity.defaultProps = {
  primary: "",
  width: "",
  height: ""
};

export default Capacity;
