import "bootstrap/dist/js/bootstrap";
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import loadLocales from "./utils/loadLocales";
import "./App.css";
import Search from "./search/Search";
import BoatPage from "./boatPage/BoatPage";
import { StateProvider } from "./state/StateProvider";
import initialState from "./state/initialState";
import reducer from "./state/reducer";
import "./assets/stylesheets/bootstrap.scss";

class App extends Component {
  constructor() {
    super();

    this.state = { initDone: false };
  }

  componentDidMount() {
    loadLocales().then(() => {
      this.setState({ initDone: true });
    });
  }

  render() {
    return (
      this.state.initDone && (
        <StateProvider initialState={initialState()} reducer={reducer}>
          <Router>
            <div id="App">
              <Switch>
                <Route exact path="/" component={Search} />
                <Route path="/boats/:boatId" component={BoatPage} />
                {/* Home page with Search is the default page */}
                <Route component={Search} />
              </Switch>
            </div>
          </Router>
        </StateProvider>
      )
    );
  }
}

export default App;
