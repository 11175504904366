import addOrAppendParamToUrl from "../utils/urlHelpers";

const reducer = (state, action) => {
  switch (action.type) {
    case "changeCurrency": {
      addOrAppendParamToUrl({
        urlParams: window.location.search,
        key: "curr",
        value: action.currency
      });
      return {
        ...state,
        currency: action.currency
      };
    }
    case "getRate":
      return {
        ...state,
        rate: action.rate
      };
    case "message":
      return {
        ...state,
        message: action.message
      };
    case "iframeConfig":
      return {
        ...state,
        countries: action.countries,
        mail: action.mail,
        theme: action.theme,
        site: action.site,
        group: action.group,
        poweredByLogo: action.poweredByLogo || state.poweredByLogo
      };
    case "changeDuration":
      return {
        ...state,
        durationInDays: action.durationInDays
      };
    case "setLocationPlaceholder":
      return {
        ...state,
        locationPlaceholder: action.locationPlaceholder
      };
    default:
      return state;
  }
};
export default reducer;
