import React from "react";
import PropTypes from "prop-types";

const Draft = ({ primary, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-655-589H369v958H-655z" />
      <path fill="#FFF" stroke="#DFDFDF" d="M-132.5-270.5h229v319h-229z" />
      <path
        fill={primary || "#FF9D23"}
        d="M3.426 11V3.74l-2.1 2.086L.5 5 4 1.5 7.5 5l-.826.826-2.1-2.086v11.02l2.1-2.086.826.826L4 17 .5 13.5l.826-.826 2.1 2.086V11zM19.5 7.006v1.272a2.67 2.67 0 0 0-.22-.008c-.593 0-.861.168-1.226.4-.431.274-.97.616-1.973.616-1.003 0-1.54-.342-1.972-.616-.365-.232-.629-.4-1.222-.4-.594 0-.858.168-1.223.4-.43.274-.968.616-1.97.616-1.004 0-1.54-.342-1.972-.616-.365-.232-.631-.4-1.222-.4V7c1.02 0 1.54.342 1.971.616.365.232.628.4 1.222.4s.857-.168 1.222-.4c.431-.274.968-.616 1.971-.616 1.003 0 1.54.342 1.971.616.365.232.628.4 1.222.4.593 0 .857-.168 1.222-.4.43-.274.968-.616 1.97-.616.08 0 .156.002.229.006zm0 3.048v1.272a2.67 2.67 0 0 0-.22-.009c-.593 0-.861.168-1.226.4-.431.275-.97.616-1.973.616-1.003 0-1.54-.341-1.972-.616-.365-.232-.629-.4-1.222-.4-.594 0-.858.168-1.223.4-.43.275-.968.616-1.97.616-1.004 0-1.54-.341-1.972-.616-.365-.232-.631-.4-1.222-.4v-1.27c1.02 0 1.54.342 1.971.617.365.232.628.4 1.222.4s.857-.168 1.222-.4c.431-.275.968-.616 1.971-.616 1.003 0 1.54.341 1.971.616.365.232.628.4 1.222.4.593 0 .857-.168 1.222-.4.43-.275.968-.616 1.97-.616.08 0 .156.002.229.006z"
      />
    </g>
  </svg>
);

Draft.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Draft.defaultProps = {
  primary: "",
  width: "",
  height: ""
};

export default Draft;
