import bcp47 from "bcp-47";
import determineLocale from "./determineLocale";
import locales from "./locales";

const parseLang = locale => bcp47.parse(locale).language;

const currentLocale = () => {
  const userLocale = determineLocale();
  const availableLocales = Object.keys(locales);

  const fullLocale = availableLocales.find(locale => locale === userLocale);
  const langLocale = availableLocales.find(
    locale => parseLang(locale) === parseLang(userLocale)
  );

  return fullLocale || langLocale || "en-US";
};

export default currentLocale;
