import React from "react";
import PropTypes from "prop-types";

const RangeValues = ({ amount, suffix }) => {
  const displayValue = suffix ? `${amount} ${suffix}` : amount;
  return amount && <span>{displayValue}</span>;
};

RangeValues.propTypes = {
  amount: PropTypes.number,
  suffix: PropTypes.string
};

RangeValues.defaultProps = {
  amount: 0,
  suffix: ""
};

export default RangeValues;
