import { isSameSaturdayWeek } from "./date";

const lowestPrice = prices =>
  prices.reduce(
    (min, price) =>
      price.discountPrice < (min.discountPrice || Infinity) ? price : min,
    {}
  );

const priceForDate = (prices, date, durationInDays) =>
  prices.find(({ from, days }) => {
    return isSameSaturdayWeek(from, date) && days === durationInDays;
  });

const currentPrice = (prices, date, durationInDays) =>
  date && priceForDate(prices, date, durationInDays)
    ? priceForDate(prices, date, durationInDays)
    : lowestPrice(prices);

const sortImages = images => {
  const mainImage = images.find(image => image.main === true);
  const secondaryImages = images.filter(image => image.main === false);

  return mainImage ? [mainImage, ...secondaryImages] : secondaryImages;
};

export { currentPrice, sortImages, lowestPrice };
