import React from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import styled from "styled-components";
import ModelInfo from "./ModelInfo";
import Loa from "../assets/svg/Loa";
import Cabin from "../assets/svg/Cabin";
import Shower from "../assets/svg/Shower";
import Draft from "../assets/svg/Draft";
import { useStateValue } from "../state/StateProvider";

const StyledModelInfo = styled.span`
  display: flex;
  justify-content: space-evenly;
`;

const ModelInfos = ({ loa, cabins, draft, wc }) => {
  const [{ theme }] = useStateValue();

  return (
    <StyledModelInfo>
      {loa ? (
        <ModelInfo
          value={Math.round(loa)}
          unit={intl.get("LOA_UNIT")}
          icon={<Loa primary={theme.primary} />}
        />
      ) : null}
      {cabins ? (
        <ModelInfo
          value={cabins}
          unit={intl.get("CABINS_UNIT", { num: cabins })}
          icon={<Cabin primary={theme.primary} />}
        />
      ) : null}
      {draft ? (
        <ModelInfo
          value={Math.round(draft)}
          unit={intl.get("DRAFT_UNIT")}
          icon={<Draft primary={theme.primary} />}
        />
      ) : null}
      {wc ? (
        <ModelInfo
          value={wc}
          unit={intl.get("WC_UNIT", { num: wc })}
          icon={<Shower primary={theme.primary} />}
        />
      ) : null}
    </StyledModelInfo>
  );
};

ModelInfos.propTypes = {
  loa: PropTypes.number,
  cabins: PropTypes.number,
  draft: PropTypes.number,
  wc: PropTypes.number
};

ModelInfos.defaultProps = {
  loa: null,
  cabins: null,
  draft: null,
  wc: null
};

export default ModelInfos;
