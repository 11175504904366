import enUS from "../locales/en-US.json";
import frFR from "../locales/fr-FR.json";
import deDE from "../locales/de-DE.json";

require("intl/locale-data/jsonp/en.js");
require("intl/locale-data/jsonp/fr.js");

const locales = {
  "en-US": enUS,
  "fr-FR": frFR,
  "de-DE": deDE
};

export default locales;
