import styled from "styled-components";
import Price from "../boat/Price";

const StyledPrice = styled(Price)`
  font-weight: 400;
  font-size: 1.5em;
  margin-right: 0.5em;
  color: ${props => props.theme.primary};
  text-align: left;
  @media (min-width: 768px) {
    text-align: ${props => props.textAlignDesktop};
  }
`;

export default StyledPrice;
