import { quoteToApi } from "./apiAdapter";
import postQuote from "./postQuote";

function handleChange(e) {
  this.setState({ [e.target.name]: e.target.value });
}

function buildHandleSubmit(quoteParams) {
  this.postQuote = postQuote.bind(this);

  return event => {
    const payload = quoteToApi(quoteParams);
    event.preventDefault();

    this.postQuote(payload);
  };
}

export { buildHandleSubmit, handleChange };
