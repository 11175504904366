import React from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import moment from "moment";
import styled from "styled-components";
import Date from "../boat/Date";
import Price from "../boat/Price";
import Location from "../boat/Location";
import "./QuoteDetails.css";

const StyledTitle = styled.h6`
  background-color: white;
  font-weight: bold;
  font-size: 0.6;
  color: ${props => props.theme.primary};
  border: 2px solid ${props => props.theme.primary};
  height: 40px;
  line-height: 40px;
  width: 100%;
`;

const StyledLabel = styled.span`
  font-weight: bold;
  font-size: 0.6;
  color: black;
`;

const StyledDate = styled(Date)`
  margin: 0 auto;
  width: 40%;
  color: black;
  text-align: left;
  font-size: 1rem;
  @media (min-width: 768px) {
    width: 25%;
  }
`;

const QuoteDetails = ({
  from,
  to,
  amount,
  modelName,
  year,
  city,
  country,
  theme
}) => (
  <div id="quote-details" className="mb-5">
    <StyledTitle className="mb-5" theme={theme}>
      <span>{intl.get("QUOTE.DETAILS")}</span>
    </StyledTitle>
    <div>
      <StyledLabel className="label">
        {intl.get("QUOTE.DATES_LABEL")}
      </StyledLabel>
      <StyledDate date={from.format("L")} label={intl.get("FROM")} />
      <StyledDate
        date={to.format("L")}
        label={intl.get("TO", { gender: "male" })}
      />
    </div>
    <div>
      <span className="label">{intl.get("QUOTE.PRICE_LABEL")}</span>
      <span>
        <Price amount={amount} style={{ display: "inline" }} />
      </span>
    </div>
    <div>
      <span className="label">{intl.get("QUOTE.BOAT_MODEL_LABEL")}</span>
      <span>
        {modelName} ({year})
      </span>
    </div>
    <div>
      <span className="label">{intl.get("LOCATION")}: </span>
      <Location city={city} country={country} />
      <span />
    </div>
  </div>
);

QuoteDetails.propTypes = {
  from: momentPropTypes.momentObj,
  to: momentPropTypes.momentObj,
  amount: PropTypes.number,
  modelName: PropTypes.string,
  year: PropTypes.number,
  city: PropTypes.string,
  country: PropTypes.string,
  theme: PropTypes.shape({ primary: PropTypes.string })
};

QuoteDetails.defaultProps = {
  from: moment("2018-01-01"),
  to: moment("2018-01-07"),
  amount: 99.99,
  modelName: "Bavaria 32",
  year: 2018,
  city: "",
  country: "",
  theme: { primary: "#F88540" }
};

export default QuoteDetails;
