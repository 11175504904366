import axios from "axios";
import { API_PRICES_URL } from "../constants";

const fetchPrices = async boatIds => {
  const { data } = await axios.get(API_PRICES_URL, {
    params: { boat_ids: boatIds }
  });
  return data;
};

export default fetchPrices;
