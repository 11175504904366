import React from "react";
import PropTypes from "prop-types";

const Phone = ({ primary, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 18 16"
  >
    <path
      fill={primary || "#FF9D23"}
      fillRule="nonzero"
      d="M3.2 6.782a11.677 11.677 0 0 0 2.106 2.862 11.677 11.677 0 0 0 2.862 2.106l1.656-1.656a.696.696 0 0 1 .36-.216.614.614 0 0 1 .414.036c.792.276 1.68.414 2.664.414.204 0 .378.072.522.216a.711.711 0 0 1 .216.522v2.646a.711.711 0 0 1-.216.522.711.711 0 0 1-.522.216c-1.728 0-3.384-.336-4.968-1.008a12.711 12.711 0 0 1-4.05-2.736 12.711 12.711 0 0 1-2.736-4.05A12.593 12.593 0 0 1 .5 1.688c0-.204.072-.378.216-.522A.711.711 0 0 1 1.238.95h2.646c.204 0 .378.072.522.216a.711.711 0 0 1 .216.522c0 .984.138 1.872.414 2.664.036.132.042.27.018.414a.6.6 0 0 1-.198.36L3.2 6.782z"
    />
  </svg>
);

Phone.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Phone.defaultProps = {
  primary: "",
  width: "",
  height: ""
};

export default Phone;
