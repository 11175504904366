import React from "react";
import PropTypes from "prop-types";
import { lighten } from "polished";
import Location from "../boat/Location";
import Localization from "../assets/svg/Localization";
import Back from "./Back";

const HeaderBoatPage = ({ modelName, year, city, country, theme }) => (
  <div
    className="col-12"
    style={{
      color: "white",
      padding: "3em",
      height: "220px",
      background: `linear-gradient(250deg, ${theme.primary}, ${lighten(
        0.1235,
        theme.primary
      )})`
    }}
  >
    <h2 className="text-left">
      {modelName}{" "}
      <span style={{ fontSize: ".4em", opacity: "0.64" }}>({year})</span>
    </h2>
    <div className="pb-4 font-weight-light">
      <Localization fill="#FFFFFF" />
      <Location city={city} country={country} />
    </div>
    <Back />
  </div>
);

HeaderBoatPage.propTypes = {
  modelName: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  theme: PropTypes.shape({ primary: PropTypes.string }).isRequired
};

export default HeaderBoatPage;
