import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { GOOGLE_API_KEY } from "../constants";

const MapContainer = styled.div`
  height: 100%;
  min-height: 240px;
  width: 100%;
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const Map = ({ center, zoom, children }) => (
  <MapContainer>
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
      center={center}
      zoom={zoom}
    >
      {children}
    </GoogleMapReact>
  </MapContainer>
);

Map.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  zoom: PropTypes.number,
  children: PropTypes.node
};

Map.defaultProps = {
  center: {
    lat: 59.95,
    lng: 30.33
  },
  zoom: 11,
  children: []
};

export default Map;
