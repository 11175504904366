import React, { useEffect } from "react";
import intl from "react-intl-universal";
import "react-dates/initialize";
import { START_DATE, END_DATE } from "react-dates/constants";
import "react-dates/lib/css/_datepicker.css";
import { DayPickerRangeController } from "react-dates";
import PropTypes from "prop-types";
import moment from "moment";
import momentPropTypes from "react-moment-proptypes";
import isInclusivelyAfterDay from "react-dates/lib/utils/isInclusivelyAfterDay";
import { prevSaturday, nextSaturday } from "../utils/date";
import { useStateValue } from "../state/StateProvider";
import DurationSelect from "./DurationSelect";
import "./DatePicker.css";

const DatePicker = ({
  startDate,
  endDate,
  focusedInput,
  onDatesChange,
  onFocusChange,
  isDayBlocked,
  onClearDates,
  noBorder,
  hideClearField
}) => {
  const [{ durationInDays }] = useStateValue();

  const isIntervalAvailable = endDay => {
    const dayBefore = endDay.clone().subtract(1, "days");
    return !isDayBlocked(dayBefore);
  };

  const handleDatesChange = dates => {
    if (isIntervalAvailable(dates.endDate)) {
      onDatesChange(dates);
    }
  };

  useEffect(() => {
    if (!startDate) return;

    const newStartDate = prevSaturday(startDate);
    const newEndDate = newStartDate.clone().add(durationInDays, "days");
    handleDatesChange({ startDate: newStartDate, endDate: newEndDate });
  }, [durationInDays]);

  return (
    <div className="date-picker">
      <DurationSelect
        isOptionDisabled={days =>
          startDate &&
          !isIntervalAvailable(prevSaturday(startDate).add(days, "days"))
        }
      />
      <div style={{ width: "100%" }}>
        <DayPickerRangeController
          key={startDate}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={onFocusChange}
          isDayBlocked={isDayBlocked}
          numberOfMonths={1}
          startDateOffset={day => prevSaturday(day)}
          endDateOffset={day => prevSaturday(day).add(durationInDays, "days")}
          renderCalendarInfo={() => <InfoPanel />}
          hideKeyboardShortcutsPanel
          daySize={35}
          isOutsideRange={day =>
            !isInclusivelyAfterDay(day, nextSaturday(moment())) ||
            isInclusivelyAfterDay(day, nextSaturday(moment()).add(2, "years"))
          }
          noBorder={noBorder}
        />
      </div>
      {hideClearField || (
        <div className="text-center">
          <button className="btn btn-link" onClick={onClearDates}>
            {intl.get("DATEPICKER.CLEAR_DATES")}
          </button>
        </div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  focusedInput: PropTypes.oneOf([START_DATE, END_DATE]),
  onDatesChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  onClearDates: PropTypes.func.isRequired,
  isDayBlocked: PropTypes.func,
  noBorder: PropTypes.bool,
  hideClearField: PropTypes.bool
};

DatePicker.defaultProps = {
  startDate: moment("2018-01-01"),
  endDate: moment("2018-01-07"),
  focusedInput: START_DATE,
  isDayBlocked: () => {},
  noBorder: false,
  hideClearField: false
};

const InfoPanel = () => (
  <div className="info-panel">
    <span role="img" aria-label="Vertical separator">
      &#x2755;
    </span>
    {intl.get("DATEPICKER.INFO_PANEL")}
  </div>
);

export default DatePicker;
