import React from "react";
import { connectRange } from "react-instantsearch-dom";
import PropTypes from "prop-types";
import linear from "rheostat/lib/algorithms/linear";
import intl from "react-intl-universal";
import { Range } from "./Range";
import { generateSnapPoints } from "../utils/range";
import RangeValue from "./RangeValue";

const maxRange = 80;
const snapPointsInterval = 1;
const minRange = 20;

const snapPoints = generateSnapPoints(minRange, maxRange, snapPointsInterval);

const LengthRange = ({ canRefine, currentRefinement, refine, min, max }) => (
  <div>
    <Range
      min={min}
      max={max}
      canRefine={canRefine}
      currentRefinement={currentRefinement}
      refine={refine}
      maxRange={maxRange}
      minRange={minRange}
      snapPoints={snapPoints}
      algorithm={linear}
      displayComponent={<RangeValue />}
      suffix={intl.get("LOA_UNIT")}
    />
  </div>
);

const ConnectedLengthRange = connectRange(LengthRange);

LengthRange.propTypes = {
  canRefine: PropTypes.bool,
  currentRefinement: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  refine: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number
};

LengthRange.defaultProps = {
  canRefine: false,
  currentRefinement: { min: 0, max: 100 },
  refine: () => {},
  min: 0,
  max: 100
};

export { ConnectedLengthRange, LengthRange };
