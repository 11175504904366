import React from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { useStateValue } from "../state/StateProvider";

const PriceForRange = ({ className, amount, style, children }) => {
  const [{ currency, rate }] = useStateValue();

  // eslint-disable-next-line no-empty-pattern
  const actualRate =
    rate &&
    rate.find(r => r.attributes.to === currency) &&
    rate.find(r => r.attributes.to === currency).attributes.rate;

  const convertValue = value =>
    currency === "EUR" ? value : value * actualRate;

  const { currentLocale } = intl.getInitOptions();

  return (
    amount && (
      <div className={className} style={style}>
        <span style={{ fontSize: ".6em" }}>{children}</span>
        {convertValue(amount).toLocaleString(currentLocale, {
          style: "currency",
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })}
      </div>
    )
  );
};

PriceForRange.propTypes = {
  amount: PropTypes.number
};

PriceForRange.defaultProps = {
  amount: 100,
  curr: "EUR"
};

export default PriceForRange;
