import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const DisplayValues = ({ displayComponent, min, max, suffix }) => {
  const displayMin =
    displayComponent &&
    React.cloneElement(displayComponent, {
      amount: min,
      suffix
    });
  const displayMax =
    displayComponent &&
    React.cloneElement(displayComponent, {
      amount: max,
      suffix
    });

  const StyledDisplayField = styled.span`
    width: 100%;
    padding: 0.5em;
    border: 2px solid #ecebeb;
  `;

  return Object.keys(displayComponent).length ? (
    <div
      style={{
        display: "flex",
        justifyContent: "stretch",
        color: "#6d7278",
        fontSize: ".7em"
      }}
      className="pt-3"
    >
      <StyledDisplayField style={{ marginRight: "1em" }}>
        {displayMin}
      </StyledDisplayField>
      <StyledDisplayField>{displayMax}</StyledDisplayField>
    </div>
  ) : null;
};

DisplayValues.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  displayComponent: PropTypes.node,
  suffix: PropTypes.string
};

DisplayValues.defaultProps = {
  min: 0,
  max: 100,
  displayComponent: "",
  suffix: ""
};
export default DisplayValues;
