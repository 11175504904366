import React from "react";
import PropTypes from "prop-types";
import { connectRefinementList } from "react-instantsearch-dom";
import MultiSelectFilter from "./MultiSelectFilter";

function RefinementSelect({ items, refine, isItemDisplayed }) {
  const options = items.filter(isItemDisplayed).map(item => ({
    ...item,
    value: item.label,
    algolia_value: item.value
  }));
  const value = options.filter(({ isRefined }) => isRefined);

  return (
    <MultiSelectFilter
      value={value}
      options={options}
      onOptionToggle={refine}
    />
  );
}

RefinementSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      count: PropTypes.number,
      value: PropTypes.array,
      isRefined: PropTypes.bool
    })
  ).isRequired,
  refine: PropTypes.func.isRequired,
  isItemDisplayed: PropTypes.func
};

RefinementSelect.defaultProps = {
  isItemDisplayed: () => true
};

const ConnectedRefinementSelect = connectRefinementList(RefinementSelect);

export { RefinementSelect, ConnectedRefinementSelect };
