import React from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { useStateValue } from "../state/StateProvider";

const Price = ({ className, amount, style, children }) => {
  const [{ currency }] = useStateValue();

  const { currentLocale } = intl.getInitOptions();

  return (
    amount && (
      <div className={className} style={style}>
        <span style={{ fontSize: ".6em" }}>{children}</span>
        {amount.toLocaleString(currentLocale, {
          style: "currency",
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })}
      </div>
    )
  );
};

Price.propTypes = {
  amount: PropTypes.number
};

Price.defaultProps = {
  amount: 100,
  curr: "EUR"
};

export default Price;
