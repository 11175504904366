import React from "react";
import intl from "react-intl-universal";
import Select, { components } from "react-select";
import { Label } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";
import FrenchFlag from "../assets/svg/FrenchFlag";
import UkFlag from "../assets/svg/UkFlag";
import DeFlag from "../assets/svg/DeFlag";
import addOrAppendParamToUrl from "../utils/urlHelpers";
import { useStateValue } from "../state/StateProvider";
import locales from "../utils/locales";

const StyledLabel = styled.label`
  display: none;
  @media (min-width: 576px) {
    display: inline;
  }
`;

// eslint-disable-next-line react/prop-types
const Option = ({ data: { flag, label }, ...other }) => {
  const Flag = flag;
  return (
    <components.Option {...other}>
      <span>
        <Flag />
      </span>
      <StyledLabel>
        &nbsp;-&nbsp;{intl.get(`${label.toUpperCase()}`)}
      </StyledLabel>
    </components.Option>
  );
};

// eslint-disable-next-line react/prop-types
const SingleValue = ({ data: { flag, label }, ...other }) => {
  const Flag = flag;
  return (
    <components.SingleValue {...other}>
      <span>
        <Flag />
      </span>
      <StyledLabel>
        &nbsp;-&nbsp;{intl.get(`${label.toUpperCase()}`)}
      </StyledLabel>
    </components.SingleValue>
  );
};

const LocaleSwitcher = props => {
  const [{ theme }, dispatch] = useStateValue();
  const { currentLocale } = intl.getInitOptions();
  const options = [
    { value: "fr-FR", label: "French", flag: FrenchFlag, param: "fr" },
    { value: "en-US", label: "English", flag: UkFlag, param: "en" },
    { value: "de-DE", label: "German", flag: DeFlag, param: "de" }
  ];

  const switchLocale = o => {
    addOrAppendParamToUrl({
      urlParams: window.location.search,
      key: "lang",
      value: o.param
    });
    intl.init({ currentLocale: o.value, locales });
    // dispatch an existing value to refresh the app without reloading
    dispatch({
      type: "iframeConfig",
      theme
    });
  };
  const customStyles = {
    control: provided => ({
      ...provided,
      border: "none"
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: "none"
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "0"
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: theme.primary,
      padding: "5px"
    })
  };
  return (
    <div className="col-3 col-md-4 offset-md-2 col-lg-3 offset-lg-3 col-xl-2 offset-xl-3 p-0">
      <Label
        style={{ color: "#6d7278", fontSize: ".8em" }}
        className="mb-0 mt-2 text-black"
      >
        {intl.get("LANGUAGE")}
      </Label>
      <Select
        {...props}
        id="selectId"
        value={options.filter(o => o.value === currentLocale)}
        components={{ Option, SingleValue }}
        onChange={o => switchLocale(o)}
        options={options.filter(o => o.value !== currentLocale)}
        styles={customStyles}
      />
    </div>
  );
};

Option.propTypes = {
  data: {
    flag: PropTypes.string,
    label: PropTypes.string
  }
};

Option.defaultProps = {
  data: { flag: "", label: "" }
};

SingleValue.propTypes = {
  data: {
    flag: PropTypes.string,
    label: PropTypes.string
  }
};

SingleValue.defaultProps = {
  data: { flag: "", label: "" }
};

export default LocaleSwitcher;
