import React from "react";
import PropTypes from "prop-types";

const Filters = ({ primary, width, height }) => (
  <svg
    id="Outlined"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    width={width || "20"}
    height={height || "20"}
  >
    <g id="Fill">
      <path
        fill={primary || "#FF9D23"}
        d="M8,12.14V2H6V12.14a4,4,0,0,0,0,7.72V30H8V19.86a4,4,0,0,0,0-7.72ZM7,18a2,2,0,1,1,2-2A2,2,0,0,1,7,18Z"
      />
      <path
        fill={primary || "#FF9D23"}
        d="M17,20.14V2H15V20.14a4,4,0,0,0,0,7.72V30h2V27.86a4,4,0,0,0,0-7.72ZM16,26a2,2,0,1,1,2-2A2,2,0,0,1,16,26Z"
      />
      <path
        fill={primary || "#FF9D23"}
        d="M29,8a4,4,0,0,0-3-3.86V2H24V4.14a4,4,0,0,0,0,7.72V30h2V11.86A4,4,0,0,0,29,8Zm-4,2a2,2,0,1,1,2-2A2,2,0,0,1,25,10Z"
      />
    </g>
  </svg>
);

Filters.propTypes = {
  primary: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

Filters.defaultProps = {
  primary: "",
  width: "",
  height: ""
};

export default Filters;
