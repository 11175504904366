import React from "react";
import PropTypes from "prop-types";
import { connectRange } from "react-instantsearch-dom";
import linear from "rheostat/lib/algorithms/linear";
import PriceForRange from "../boat/PriceForRange";
import { generateSnapPoints } from "../utils/range";
import { Range } from "./Range";

const minRange = 0;
const maxRange = 10000;
const snapPointsInterval = 100;
const snapPoints = generateSnapPoints(0, maxRange, snapPointsInterval);

const PriceRange = ({ canRefine, currentRefinement, max, min, refine }) => (
  <Range
    min={min}
    max={max}
    canRefine={canRefine}
    currentRefinement={currentRefinement}
    refine={refine}
    minRange={minRange}
    maxRange={maxRange}
    snapPoints={snapPoints}
    algorithm={linear}
    displayComponent={<PriceForRange />}
  />
);

const ConnectedPriceRange = connectRange(PriceRange);

PriceRange.propTypes = {
  canRefine: PropTypes.bool,
  currentRefinement: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  refine: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number
};

PriceRange.defaultProps = {
  min: 0,
  max: 100,
  canRefine: false,
  currentRefinement: { min: 0, max: 100 },
  refine: () => {}
};

export { PriceRange, ConnectedPriceRange };
