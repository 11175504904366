import React from "react";
import PropTypes from "prop-types";
import { Alert as ReactStrapAlert } from "reactstrap";

const Alert = ({ status, message }) =>
  message && <ReactStrapAlert color={status}>{message}</ReactStrapAlert>;

Alert.propTypes = {
  status: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark"
  ]),
  message: PropTypes.string
};

Alert.defaultProps = {
  status: "success",
  message: "Quote successfully created"
};
export default Alert;
