import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import momentPropTypes from "react-moment-proptypes";
import intl from "react-intl-universal";
import UnStyledLink from "../utils/UnStyledLink";
import CardPrice from "../utils/CardPrice";
import Date from "./Date";
import Location from "./Location";
import ModelInfos from "./ModelInfos";
import BoatImage from "../utils/BoatImage";
import { useStateValue } from "../state/StateProvider";
import Localization from "../assets/svg/Localization";
import calendar from "../assets/images/calendar.svg";

const InformationButton = styled.button`
  cursor: pointer;
  border: ${props => `1px solid ${props.primary}`};
  background-color: white;
  color: ${props => props.primary};

  &:hover {
    background-color: ${props => props.primary};
    color: white;
  }
`;

const StyledBoatImage = styled(BoatImage)`
  object-fit: cover;
  width: 100%;
`;

const DateContainer = styled.div`
  color: #6d7278;
  width: 100%;
`;

const DescriptionContainer = styled.div`
  color: #6d7278;
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  color: #175377;
`;

const BoatCard = ({
  city,
  country,
  imageUrl,
  modelName,
  uri,
  year,
  from,
  to,
  amount,
  loa,
  cabins,
  draft,
  wc,
  date
}) => {
  const [{ currency, theme }] = useStateValue();

  return (
    <UnStyledLink
      to={uri}
      className="col-12 col-md-4 col-lg-6 col-xl-4 col-xxl-3 col-xxxl-2 col-xxxxl-1 px-3 pb-4"
    >
      <div
        style={{
          height: "400px",
          backgroundColor: "white",
          border: "1px solid #dfdfdf"
        }}
        className="pb-3"
      >
        <div style={{ height: "45%", position: "relative" }}>
          <StyledBoatImage url={imageUrl} />
          <CardPrice
            textAlignDesktop="left"
            amount={amount}
            className="shadow px-2"
            curr={currency}
            primary={theme.primary}
          >
            {!date && `${intl.get("PRICES_FROM")} :`}
          </CardPrice>
        </div>

        <DescriptionContainer className="px-4 pt-4 font-weight-light">
          <div>
            <h2
              className="font-weight-normal"
              style={{
                display: "inline-block",
                fontSize: "1.5em",
                lineHeight: "1em",
                color: theme.primary
              }}
            >
              {modelName.length > 18
                ? `${modelName.substring(0, 16)}...`
                : modelName}{" "}
              <span style={{ fontSize: "12px", color: "#dfdfdf" }}>
                ({year})
              </span>
            </h2>
            <h4
              className="mb-1 font-weight-light"
              style={{ fontSize: "12px", color: "#6d7278" }}
            >
              <Localization />
              <Location city={city} country={country} />
            </h4>
            <DateContainer className="py-1">
              <img src={calendar} alt="calendar" />
              <Date date={from.format("ll")} />
              <span>-</span>
              <Date date={to.format("ll")} />
            </DateContainer>
          </div>
          <InformationButton primary={theme.primary} className="mt-3">
            {intl.get("BOAT_CARD.MORE_INFOS")}
          </InformationButton>
          <hr style={{ borderTop: "1px solid #dfdfdf", width: "100%" }} />

          <ModelInfos loa={loa} cabins={cabins} draft={draft} wc={wc} />
        </DescriptionContainer>
      </div>
    </UnStyledLink>
  );
};

BoatCard.propTypes = {
  imageUrl: PropTypes.string,
  modelName: PropTypes.string,
  uri: PropTypes.string,
  year: PropTypes.number,
  city: PropTypes.string,
  country: PropTypes.string,
  from: momentPropTypes.momentObj,
  to: momentPropTypes.momentObj,
  amount: PropTypes.number,
  loa: PropTypes.number,
  cabins: PropTypes.number,
  draft: PropTypes.number,
  wc: PropTypes.number,
  date: momentPropTypes.momentObj
};

BoatCard.defaultProps = {
  imageUrl: "https://flickr.com/some-image.jpg",
  modelName: "Dufour 460",
  uri: "boats/12345?from=2018-10-21",
  year: 2018,
  city: "Marseille",
  country: "France",
  from: moment("2018-01-01"),
  to: moment("2018-01-07"),
  amount: 100,
  loa: 46,
  cabins: 3,
  draft: 2.5,
  wc: 2,
  date: moment("2018-01-01")
};

export default BoatCard;
