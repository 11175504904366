const MARKER_SIZE = 70;

const markerStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to center the object at the 0,0 coordinates
  position: "absolute",
  width: MARKER_SIZE,
  height: MARKER_SIZE + 2,
  left: -MARKER_SIZE / 2,
  top: -MARKER_SIZE / 2,
  padding: 5
};

export default markerStyle;
