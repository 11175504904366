import React, { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { connectHits } from "react-instantsearch-dom";
import fetchPrices from "./fetchPrices";

const boatIdsFromHits = hits => hits.map(({ objectID }) => objectID);

const extractPricesForBoat = (boatId, prices) => {
  const pricesForBoat = prices.filter(
    ({ attributes }) => boatId === attributes.boat_id
  );
  return pricesForBoat.reduce((acc, price) => {
    acc[price.id] = { id: price.id, ...price.attributes };
    return acc;
  }, {});
};

const mergeHitsWithPrices = (hits, prices) =>
  hits.map(hit => {
    return {
      ...hit,
      prices: extractPricesForBoat(+hit.objectID, prices)
    };
  });

const withPrices = WrappedComponent => {
  return ({ hits, ...rest }) => {
    if (!hits.length) return null;

    const [newHits, setNewHits] = useState(null);

    useEffect(() => {
      const boatIds = boatIdsFromHits(hits);

      const completeHits = debounce(async () => {
        const { data } = await fetchPrices(boatIds);
        setNewHits(mergeHitsWithPrices(hits, data));
      }, 200);

      completeHits();

      return () => {
        completeHits.cancel();
      };
    }, [hits]);

    return newHits && <WrappedComponent hits={newHits} {...rest} />;
  };
};

const connectHitsWithPrices = WrappedComponent =>
  connectHits(withPrices(WrappedComponent));

export default connectHitsWithPrices;
