import React, { Component } from "react";
import PropTypes from "prop-types";
import qs from "qs";
import intl from "react-intl-universal";
import { START_DATE } from "react-dates/constants";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FaChevronLeft } from "react-icons/fa";
import Localization from "../assets/svg/Localization";
import ModalComponent from "./ModalComponent";
import Map from "../map/Map";
import Marker from "../map/Marker";
import Location from "../boat/Location";
import Date from "../boat/Date";
import Equipments from "./Equipments";
import DatePicker from "../search/DatePicker";
import QuoteDetails from "./QuoteDetails";
import { sortImages, currentPrice, lowestPrice } from "../utils/boat";
import {
  onDatesChange,
  onClearDates,
  setInitialHistory,
  onPopState
} from "../utils/boatPage";
import { getBoat, formatPrices } from "../utils/api";
import { prevSaturday, parseDateISO } from "../utils/date";
import { onFocusChange, dayBlockedFromDates } from "../utils/datepicker";
import ContactFormController from "./ContactFormController";
import StateContext from "../state/context";
import CurrencySwitcher from "../search/CurrencySwitcher";
import Message from "../search/Message";
import BoatPageModelInfos from "./BoatPageModelInfos";
import BoatPageSlider from "./BoatPageSlider";
import HeaderBoatPage from "./HeaderBoatPage";
import Charts from "./Charts";
import calendar from "../assets/images/calendar.svg";
import StyledPrice from "./StyledPrice";
import scrollToTopOfIframe from "../utils/iframe";

const mediaQueryList = window.matchMedia("(min-width: 720px)");

const Divider = styled.hr`
  margin-top: 3em;
  border-top: 1px solid #dfdfdf;
  width: 100%;
`;

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.59);
  @media (min-width: 992px) {
    display: none;
  }
`;

const StyledDate = styled(Date)`
  margin: 0 auto;
  width: 40%;
  color: #6d7278;
  text-align: left;
  @media (min-width: 768px) {
    width: 25%;
  }
`;

const StyledButton = styled.button`
  background-color: ${props =>
    props.negative ? "white" : props.theme.primary};
  font-weight: bold;
  font-size: 0.6;
  color: ${props => (props.negative ? props.theme.primary : "white")};
  border: ${props => `2px solid ${props.theme.primary}`};
  height: 40px;
`;

const StyledBox = styled.div`
  max-width: 500px;
  margin-top: -60px;
  text-align: left;
  h1,
  h2,
  p {
    text-align: left;
  }
`;
const BackgroundColor = styled.div`
  background-color: white;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.23);
`;
class BoatPage extends Component {
  static contextType = StateContext;
  constructor(props, context) {
    super(props, context);
    this.boatId = this.props.match.params.boatId;
    const { search } = this.props.location;
    const { from } = qs.parse(search, { ignoreQueryPrefix: true });
    const momentFrom = parseDateISO(from);
    const [{ durationInDays }] = this.context;

    const initialStartDate = momentFrom && prevSaturday(momentFrom);
    const initialEndDate =
      initialStartDate && initialStartDate.clone().add(durationInDays, "days");

    this.state = {
      boat: null,
      startDate: initialStartDate,
      endDate: initialEndDate,
      focusedInput: START_DATE,
      isDesktop: false,
      isDatePickerOpen: false,
      isContactFormOpen: false
    };

    this.onDatesChange = onDatesChange.bind(this);
    this.onClearDates = onClearDates.bind(this);
    this.onFocusChange = onFocusChange.bind(this);
    this.onPopState = onPopState.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);

    window.addEventListener("popstate", this.onPopState);
  }

  componentDidMount() {
    const { boatId } = this;
    getBoat(boatId).then(boat => this.saveBoatToState(boat));
    setInitialHistory(this.state.startDate);
    this.updateWindowWidth();
    mediaQueryList.addListener(this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopState);
    mediaQueryList.removeListener(this.updateWindowWidth);
  }

  saveBoatToState(boat) {
    this.setState({ boat });
  }

  updateWindowWidth() {
    const isDesktop = mediaQueryList.matches;
    this.setState({ isDesktop });
  }

  render() {
    const [{ currency, theme, durationInDays }] = this.context;
    const { boat } = this.state;
    if (!boat) return null;

    const { year } = boat;
    const { loa, cabins, capacity, wc } = boat.boatModel;
    const boatId = boat.id;
    const modelName = boat.boatModel.name;
    const images = sortImages(boat.images);
    const prices = formatPrices(boat.prices);
    const fromDates = prices.map(price => price.from);
    const center = { lat: boat.latitude, lng: boat.longitude };

    // 'equipment' is invariable, so in our public facing API it's used with it's correct non pluralised form
    // in our private API, we use 'equipments' for a collection of 'equipment' to keep our sanity
    // http://learnersdictionary.com/qa/equipments-equipment-noncount-mass-noun-singular-plural
    const equipments = boat.equipment;

    const date = this.state.startDate;
    const price = currentPrice(prices, date, durationInDays);
    const amount = price[currency.toLowerCase()];
    const { from, to, discountPrice } = price;
    const isFilterUsed =
      lowestPrice(boat.prices).discountPrice === discountPrice;

    const toggleDatePicker = () => {
      scrollToTopOfIframe();
      this.setState(prevState => ({
        isDatePickerOpen: !prevState.isDatePickerOpen
      }));
    };

    const toggleContactForm = () => {
      scrollToTopOfIframe();

      this.setState(prevState => ({
        isContactFormOpen: !prevState.isContactFormOpen
      }));
    };

    const fullScreenModalStyle = !this.state.isDesktop
      ? { margin: "0", height: "100%", maxWidth: "unset" }
      : {};

    const { city, country } = boat;

    const openQuoteForm = () => {
      scrollToTopOfIframe();
      this.setState({
        isDatePickerOpen: false,
        isContactFormOpen: true
      });
    };

    return (
      <div style={{ paddingBottom: "6em" }}>
        <div
          className="container mt-3"
          style={{
            borderLeft: "1px solid #dfdfdf",
            borderRight: "1px solid #dfdfdf",
            color: "#6d7278",
            maxWidth: "97%"
          }}
        >
          <div className="d-none">
            <CurrencySwitcher />
          </div>
          <div className="boatContainer row">
            <HeaderBoatPage
              modelName={modelName}
              year={year}
              city={city}
              country={country}
              theme={theme}
            />

            <div className="infoContainer pb-5 offset-sm-1 offset-md-2 col-sm-10 col-md-8 offset-lg-0 col-lg-7">
              <BoatPageSlider
                isDesktop={this.state.isDesktop}
                images={images}
                theme={theme}
              />
              <Message />
              <div className="mt-3 Characteristics col-12 px-0">
                <div className="font-weight-bold">
                  {intl.get("CHARACTERISTICS")}
                </div>
                <BoatPageModelInfos
                  loa={loa}
                  year={year}
                  cabins={cabins}
                  capacity={capacity}
                  wc={wc}
                  theme={theme}
                />
                <Divider />
              </div>
              <div className="Equipment row">
                <div className="col-12 mt-4">
                  <Equipments
                    boatId={boatId}
                    theme={theme}
                    onNoContentClick={openQuoteForm}
                  >
                    {equipments}
                  </Equipments>
                  <Divider />
                </div>
              </div>
              <div
                style={{ userSelect: "none" }}
                className="mt-3 Graphs col-12"
              >
                <div className="font-weight-bold mb-5">
                  {intl.get("PRICE_PERIODS.DURATION")}
                </div>
                <Charts
                  prices={prices}
                  onDatesChange={this.onDatesChange}
                  currency={currency}
                  theme={theme}
                />
              </div>
              <div className="Map mx-0 p-0 col-12 row my-5 mb-5">
                <div className="col-12 m-0 p-0">
                  <div className="font-weight-bold">{intl.get("LOCATION")}</div>
                  <Localization fill="#6A6A6A" />
                  <Location city={city} country={country} />
                  <Map center={center} zoom={11}>
                    <Marker
                      lat={center.lat}
                      lng={center.lng}
                      color={theme.primary}
                    />
                  </Map>
                </div>
              </div>
            </div>

            <div
              className="sticky-top col-5 d-none d-lg-block d-xl-block"
              style={{ top: "60px", height: "100%" }}
            >
              <StyledBox>
                <BackgroundColor className="p-4 m-3">
                  <StyledPrice amount={amount} theme={theme} />
                  <img src={calendar} alt="calendar" />

                  <StyledDate date={from.format("L")} />
                  <StyledDate date={to.format("L")} label="-" />
                  <div className="my-5">
                    <DatePicker
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      focusedInput={this.state.focusedInput}
                      onDatesChange={this.onDatesChange}
                      onFocusChange={this.onFocusChange}
                      onClearDates={this.onClearDates}
                      isDayBlocked={dayBlockedFromDates(fromDates)}
                      hideClearField
                      noBorder
                    />
                  </div>
                  <div className="text-center">
                    <ModalComponent title={intl.get("QUOTE.TITLE")}>
                      {toggle => (
                        <div>
                          <QuoteDetails
                            from={from}
                            modelName={modelName}
                            amount={amount}
                            year={year}
                          />
                          <ContactFormController
                            boatId={boatId}
                            price={amount}
                            submitId="btn-quote-submit"
                            toggle={toggle}
                          />
                        </div>
                      )}
                    </ModalComponent>
                    <div style={{ display: "flex", margin: "1em" }}>
                      <StyledButton
                        theme={theme}
                        className="mx-0 px-0 col"
                        onClick={this.onClearDates}
                        negative
                      >
                        {intl.get("DATEPICKER.CLEAR_DATES").toUpperCase()}
                      </StyledButton>
                      <StyledButton
                        theme={theme}
                        className="mx-0 px-0 col"
                        onClick={() => {
                          scrollToTopOfIframe();
                          this.setState({
                            isDatePickerOpen: false,
                            isContactFormOpen: true
                          });
                        }}
                      >
                        {intl.get("QUOTE.OPEN_BUTTON").toUpperCase()}
                      </StyledButton>
                    </div>
                  </div>
                </BackgroundColor>
              </StyledBox>
            </div>
          </div>
        </div>

        <StyledFooter>
          <div className="px-3 py-2 row">
            <div className="priceAndDate col-xs-12 col-sm-4 ">
              {isFilterUsed && "Prices start from:"}
              <StyledPrice amount={amount} />
              <img src={calendar} alt="calendar" />
              <StyledDate date={from.format("L")} />
              <StyledDate date={to.format("L")} label="-" />
            </div>
            <div className="buttons pt-2 col-xs-12 col-sm-8">
              <div className="row mx-1 mb-1">
                <StyledButton
                  theme={theme}
                  className="col-6"
                  onClick={() => toggleDatePicker()}
                  negative
                >
                  {isFilterUsed ? "CHOOSE DATES" : "CHANGE DATES"}
                </StyledButton>
                <StyledButton
                  theme={theme}
                  className="col-6"
                  onClick={() => {
                    toggleContactForm();
                  }}
                >
                  {intl.get("QUOTE.OPEN_BUTTON").toUpperCase()}
                </StyledButton>

                <Modal
                  fade={false}
                  className="DatePickerModal"
                  isOpen={this.state.isDatePickerOpen}
                  toggle={toggleDatePicker}
                  isDesktop={this.state.isDesktop}
                  style={{
                    ...fullScreenModalStyle
                  }}
                  contentClassName={(!this.state.isDesktop && "w-100") || ""}
                >
                  <ModalHeader
                    onClick={() => {
                      toggleDatePicker();
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end"
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <FaChevronLeft />
                      {intl.get("BACK_BOAT_PAGE").toUpperCase()}
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <div className="priceAndDate col-xs-12 col-sm-4 ">
                      {isFilterUsed && "Prices start from:"}
                      <StyledPrice amount={amount} />

                      {!isFilterUsed ? null : (
                        <>
                          <StyledDate
                            date={from.format("ll")}
                            label={intl.get("FROM")}
                          />
                          <StyledDate
                            date={to.format("ll")}
                            label={intl.get("TO", {
                              gender: "male"
                            })}
                          />
                        </>
                      )}
                    </div>
                    <DatePicker
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      focusedInput={this.state.focusedInput}
                      onDatesChange={this.onDatesChange}
                      onFocusChange={this.onFocusChange}
                      onClearDates={this.onClearDates}
                      isDayBlocked={dayBlockedFromDates(fromDates)}
                      hideClearField
                      noBorder
                    />
                  </ModalBody>
                  <ModalFooter className="row mx-1">
                    <StyledButton
                      theme={theme}
                      className="mx-0 px-0 col"
                      onClick={this.onClearDates}
                      negative
                    >
                      {intl.get("DATEPICKER.CLEAR_DATES").toUpperCase()}
                    </StyledButton>
                    <StyledButton
                      theme={theme}
                      className="mx-0 px-0 col"
                      onClick={openQuoteForm}
                    >
                      {intl.get("QUOTE.OPEN_BUTTON").toUpperCase()}
                    </StyledButton>
                  </ModalFooter>
                </Modal>

                <Modal
                  fade={false}
                  className="ContactFormModal"
                  isOpen={this.state.isContactFormOpen}
                  toggle={toggleContactForm}
                  isDesktop={this.state.isDesktop}
                  style={{
                    ...fullScreenModalStyle
                  }}
                  contentClassName={(!this.state.isDesktop && "w-100") || ""}
                >
                  <ModalHeader toggle={toggleContactForm} />
                  <ModalBody>
                    <div>
                      <QuoteDetails
                        from={from}
                        to={to}
                        modelName={modelName}
                        amount={amount}
                        year={year}
                        city={city}
                        country={country}
                        theme={theme}
                      />
                      <ContactFormController
                        from={from}
                        to={to}
                        boatId={boatId}
                        price={amount}
                        submitId="btn-quote-submit"
                        toggle={toggleContactForm}
                      />
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </StyledFooter>
      </div>
    );
  }
}

BoatPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      boatId: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    search: PropTypes.string
  })
};

BoatPage.defaultProps = {
  match: {
    params: {
      boatId: "12345"
    }
  },
  location: {
    search: "?from=2018-10-22"
  }
};

export default BoatPage;
