import React from "react";
import intl from "react-intl-universal";
import humanizeString from "humanize-string";
import CheckboxesFilter from "./CheckboxesFilter";

function BoatTypeFilter() {
  return (
    <CheckboxesFilter
      attribute="type"
      translateItem={({ label }) =>
        intl
          .get(`BOAT_TYPE.${label.toUpperCase()}`)
          .defaultMessage(humanizeString(label))
      }
    />
  );
}

export default BoatTypeFilter;
