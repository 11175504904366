import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const DateHolder = styled.span`
  text-align: center;
  font-size: 12px;
`;

const Date = ({ className, date, label }) =>
  date && (
    <DateHolder className={className}>
      {label} {date}
    </DateHolder>
  );

Date.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  label: PropTypes.string
};

Date.defaultProps = {
  className: "",
  date: "",
  label: ""
};

export default Date;
