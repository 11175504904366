import React, { Component } from "react";
import PropTypes from "prop-types";
import lazySizes from "lazysizes"; // eslint-disable-line no-unused-vars
import Imgix from "react-imgix";
import queryString from "query-string";

class ImgixImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0
    };

    this.setRef = this.setRef.bind(this);
  }

  setRef(node) {
    if (!node) return;

    const { width, height } = node.parentNode.getBoundingClientRect();
    this.setState({ width, height });
  }

  render() {
    const { url, className } = this.props;
    const { width, height } = this.state;

    const commonParams = {
      fit: "crop",
      crop: "edges",
      auto: "enhance",
      fm: "pjpg"
    };
    const lowQualityParams = { q: 1, blur: 100 };

    const lowQualityUrl = `${url}?w=${width}&h=${height}&${queryString.stringify(
      commonParams
    )}&${queryString.stringify(lowQualityParams)}`;

    return (
      <Imgix
        className={`lazyload ${className}`}
        src={url}
        width={width}
        height={height}
        imgixParams={commonParams}
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes"
        }}
        htmlAttributes={{
          src: lowQualityUrl,
          ref: this.setRef
        }}
      />
    );
  }
}

ImgixImage.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string
};

ImgixImage.defaultProps = {
  url: "",
  className: ""
};

export default ImgixImage;
