import intl from "react-intl-universal";
import { connectStats } from "react-instantsearch-dom";
import PropTypes from "prop-types";

const DisplayHits = ({ nbHits }) => {
  const { currentLocale } = intl.getInitOptions();
  const nbHitsLocalized = nbHits.toLocaleString(currentLocale);

  return intl.get("DISPLAY_HITS", { nbHitsLocalized });
};

DisplayHits.propTypes = {
  nbHits: PropTypes.number
};

DisplayHits.defaultProps = {
  nbHits: 0
};

const ConnectedDisplayHits = connectStats(DisplayHits);

export { DisplayHits, ConnectedDisplayHits };
