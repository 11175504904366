import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useStateValue } from "../state/StateProvider";

function MultiSelectFilter({ onOptionToggle, ...rest }) {
  const [{ theme }] = useStateValue();
  const customStyles = {
    container: provided => ({
      ...provided,
      marginTop: "auto",
      marginRight: " auto",
      marginBottom: " unset",
      marginLeft: " auto",
      width: "100%"
    }),
    control: provided => ({
      ...provided,
      height: "40px",
      borderRadius: "0",
      border: `2px solid ${theme.primary}`,
      minHeight: "unset",
      alignContent: "center"
    })
  };

  const handleChange = (selected, { action, option, removedValue }) => {
    if (action === "select-option") {
      onOptionToggle(option.algolia_value);
    } else if (action === "remove-value") {
      onOptionToggle(removedValue.algolia_value);
    } else if (action === "clear") {
      onOptionToggle([]);
    }
  };

  return (
    <Select isMulti styles={customStyles} onChange={handleChange} {...rest} />
  );
}

MultiSelectFilter.propTypes = {
  onOptionToggle: PropTypes.func.isRequired
};

export default MultiSelectFilter;
