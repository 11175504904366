import React from "react";
import intl from "react-intl-universal";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";
import Alert from "./Alert";
import { useStateValue } from "../state/StateProvider";

const StyledTitle = styled.h6`
  background-color: white;
  font-weight: bold;
  font-size: 0.6;
  color: ${props => props.theme.primary};
  border: 2px solid ${props => props.theme.primary};
  height: 40px;
  line-height: 40px;
  width: 100%;
`;

const ContactForm = ({
  handleChange,
  handleSubmit,
  status,
  message,
  submitId
}) => {
  const [{ theme, group, poweredByLogo }] = useStateValue();

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div id="customer-informations" className="mt-3">
          <StyledTitle className="text-center mb-5" theme={theme}>
            {intl.get("QUOTE.INFORMATIONS")}
          </StyledTitle>
          <FormGroup>
            <Label className="sr-only" for="email">
              {intl.get("QUOTE.EMAIL_LABEL")}
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder={intl.get("QUOTE.EMAIL_PLACEHOLDER")}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className="sr-only" for="first-name">
              {intl.get("QUOTE.FIRST_NAME_LABEL")}
            </Label>
            <Input
              type="text"
              name="firstName"
              id="first-name"
              placeholder={intl.get("QUOTE.FIRST_NAME_PLACEHOLDER")}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="sr-only" for="last-name">
              {intl.get("QUOTE.LAST_NAME_LABEL")}
            </Label>
            <Input
              type="text"
              name="lastName"
              id="last-name"
              placeholder={intl.get("QUOTE.LAST_NAME_PLACEHOLDER")}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="sr-only" for="phone">
              {intl.get("QUOTE.PHONE_NUMBER_LABEL")}
            </Label>
            <Input
              type="phone"
              name="phone"
              id="phone"
              placeholder={intl.get("QUOTE.PHONE_NUMBER_PLACEHOLDER")}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="sr-only" for="message">
              {intl.get("QUOTE.MESSAGE_LABEL")}
            </Label>
            <Input
              type="textarea"
              name="message"
              id="message"
              placeholder={intl.get("QUOTE.MESSAGE_PLACEHOLDER")}
              onChange={handleChange}
            />
          </FormGroup>
          <div className="row my-2 justify-content-center">
            <Alert status={status} message={message} />
          </div>
          <Label className="sr-only" for="skipper">
            {intl.get("QUOTE.SKIPPER_LABEL")}
          </Label>
          <FormGroup id="skipper">
            <Label>
              <Input
                id="skipper"
                type="checkbox"
                className="position-relative m-0 mr-3"
              />
              {intl.get("QUOTE.SKIPPER_OPT_IN")}
            </Label>
          </FormGroup>

          {// when group is absent we display a logo
          !group && (
            <div className="row justify-content-center align-items-center">
              <div className="col-3 col-md-3 d-flex p-0">
                <span className="align-self-end w-100 text-right">
                  {intl.get("QUOTE.POWERED_BY")}
                </span>
              </div>

              <div className="col-4">
                <img
                  src={poweredByLogo}
                  alt="icon"
                  className="img-fluid mb-1"
                />
              </div>
            </div>
          )}
          <div className="col-12">
            <Button
              outline
              color="success"
              className="d-block mx-auto mt-3"
              id={submitId}
            >
              {intl.get("QUOTE.SUBMIT_BUTTON")}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

ContactForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  status: PropTypes.string,
  message: PropTypes.string,
  submitId: PropTypes.string
};

ContactForm.defaultProps = {
  handleChange: () => {},
  handleSubmit: () => {},
  status: "success",
  message: "Quote succesfully created",
  submitId: ""
};

export default ContactForm;
