import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Label } from "reactstrap";
import intl from "react-intl-universal";
import moment from "moment";
import momentPropTypes from "react-moment-proptypes";
import { START_DATE, END_DATE } from "react-dates/constants";
import { ConnectedStats } from "./Stats";
import ModalComponent from "../boatPage/ModalComponent";
import ContactFormController from "../boatPage/ContactFormController";
import { ConnectedMap } from "../map/ConnectedMap";
import DateModalToggleButton from "./DateModalToggleButton";
import { formatDateISO } from "../utils/date";
import ModalFooter from "./ModalFooter";
import DatePicker from "./DatePicker";
import CurrencySwitcher from "./CurrencySwitcher";
import FilterModalToggleButton from "./FilterModalToggleButton";
import Filters from "./Filters";
import { formatPriceRangeFacet } from "../utils/range";
import ClearRefinements from "./ClearRefinements";
import Phone from "../assets/svg/Phone";
import "./Header.css";
import { useStateValue } from "../state/StateProvider";
import LocaleSwitcher from "./LocaleSwitcher";
import LocationSelect from "./LocationSelect";

const StyledButton = styled.button`
  background-color: white;
  font-weight: bold;
  font-size: 0.6;
  color: ${props => props.primary};
  border: none;
  &:hover {
    background-color: #1c5377;
  }
`;

const StyledSearchSubtitle = styled.div`
  color: #6d7278;
  font-size: 1.6em;
  font-weight: 400;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 1em;
  }
`;

const Header = ({
  onSuggestSelect,
  startDate,
  isDesktop,
  endDate,
  focusedInput,
  onDatesChange,
  onFocusChange,
  onClearDates,
  priceRefinement
}) => {
  const priceRangeFacet = formatPriceRangeFacet(startDate);

  const [{ theme }] = useStateValue();

  return (
    <div className="row mb-4 mx-1">
      <div
        style={{
          borderBottom: "1px solid #dfdfdf",
          borderLeft: "1px solid #dfdfdf",
          borderRight: "1px solid #dfdfdf"
        }}
        className="pl-2 col-sm-12 col-md-7  container"
      >
        <div className="firstRow pt-2 row align-items-end">
          <StyledSearchSubtitle className="text-left row text-black col-md-12 col-sm-12">
            <div className=" col-6 col-sm-12">
              {intl.get("SEARCH_TITLE").toUpperCase()}
            </div>
            <div className=" col-6 col-sm-12" style={{ fontSize: ".5em" }}>
              {intl.get("SEARCH_SUBTITLE")}
            </div>
          </StyledSearchSubtitle>
          <div className="mt-4 col-md-6 col-8">
            <ModalComponent
              toggleButton={
                <StyledButton primary={theme.primary}>
                  <Phone primary={theme.primary} />
                  <span style={{ whiteSpace: "nowrap" }}>
                    {intl.get("QUOTE.ARRANGE_CALLBACK").toUpperCase()}
                  </span>
                </StyledButton>
              }
            >
              {toggle => (
                <div id="contact">
                  <ContactFormController
                    toggle={toggle}
                    submitId="btn-contact-submit"
                  />
                </div>
              )}
            </ModalComponent>
          </div>
          <LocaleSwitcher />
        </div>

        <div className="secondRow row mx-1 py-3 ">
          <div className="firstInput p-0  col-xs-12 col-lg-4">
            <Label
              style={{ color: "#6d7278", fontSize: ".8em" }}
              className="mb-0 mt-2 text-black"
            >
              {intl.get("LOCATION")}
            </Label>
            <LocationSelect onSuggestSelect={onSuggestSelect} />
          </div>

          <div className="secondInput  col-xs-8 col-lg-4 pl-0 pr-2 pr-sm-0 col-6">
            <ModalComponent
              title={intl.get("DATE_MODAL.TITLE")}
              isDesktop={isDesktop}
              positionedModal
              toggleButton={
                <DateModalToggleButton
                  placeholder={
                    formatDateISO(startDate) ||
                    intl.get("DATE_MODAL.FROM_PLACEHOLDER")
                  }
                  value={formatDateISO(startDate) || ""}
                />
              }
              modalFooter={<ModalFooter />}
            >
              {() => (
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  focusedInput={focusedInput}
                  onDatesChange={onDatesChange}
                  onFocusChange={onFocusChange}
                  onClearDates={onClearDates}
                  noBorder
                />
              )}
            </ModalComponent>
          </div>

          <div className="thirdInput  p-0 col-lg-4 col-6">
            <div className="">
              <div className="w-100">
                <Label
                  style={{ color: "#6d7278", fontSize: ".8em" }}
                  className="mb-0 mt-2 text-black"
                >
                  {intl.get("CURRENCY")}
                </Label>
                <CurrencySwitcher />
              </div>
            </div>
          </div>
          <div className="d-md-none col-12">
            <ConnectedStats className="text-black" />
          </div>

          <div className="text-right px-0 d-none d-md-block col-12">
            <ConnectedStats className="text-black" />
          </div>
        </div>
      </div>

      <div
        style={{
          border: "1px solid #dfdfdf"
        }}
        className="col-md-5 col-xs-12 px-0"
      >
        <ConnectedMap />
      </div>
      <div
        style={{ border: `2px solid ${theme.primary}`, height: "3.5em" }}
        className="d-lg-none col-12 mt-4 text-right"
      >
        <ModalComponent
          isDesktop={isDesktop}
          toggleButton={<FilterModalToggleButton theme={theme} />}
          positionedModal
          width="80vw"
          modalFooter={<ModalFooter />}
        >
          {() => (
            <Filters
              isDesktop={isDesktop}
              priceRangeFacet={priceRangeFacet}
              priceRefinement={priceRefinement}
            >
              <ClearRefinements />
            </Filters>
          )}
        </ModalComponent>
      </div>
    </div>
  );
};

Header.propTypes = {
  onSuggestSelect: PropTypes.func,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  isDesktop: PropTypes.bool,
  focusedInput: PropTypes.oneOf([START_DATE, END_DATE]),
  onDatesChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  onClearDates: PropTypes.func.isRequired,
  priceRefinement: PropTypes.shape({
    max: PropTypes.string,
    min: PropTypes.string
  })
};
Header.defaultProps = {
  onSuggestSelect: () => {},
  startDate: moment("2018-01-01"),
  endDate: moment("2018-01-07"),
  isDesktop: false,
  focusedInput: START_DATE,
  priceRefinement: { max: null, min: null }
};

export default Header;
