// add polyfill for `URLSearchParams` although it's already in jsdom. This is because `create-react-scripts`
// doesn't use jest 22.x yet... This can be remove as soon as jest version is bumped in `create-react-scripts`
import URLSearchParams from "url-search-params";
import { formatDateISO, parseDateISO, nextSaturday } from "./date";
import { BOAT_PAGE_PATH, URL_UPDATE_DELAY } from "../constants";
import { currentPrice } from "./boat";

const boatUri = (boatId, from) => {
  // retrieve `lang` from old params
  const oldParams = new URLSearchParams(window.location.search);

  const lang = oldParams.get("lang");

  // set `lang` and `from` in params
  const params = new URLSearchParams();
  if (from) params.set("from", formatDateISO(from));
  if (lang) params.set("lang", lang);

  return `${BOAT_PAGE_PATH}/${boatId}?${params}`;
};

const fromToUrl = from => {
  const params = new URLSearchParams(window.location.search);
  if (from) params.set("from", from);

  return `${window.location.pathname}?${params}`;
};

const updateURL = from => {
  // update the URL when there is a from date
  clearTimeout(this.debouncedUpdateFrom);
  const fromISO = formatDateISO(from);
  this.debouncedUpdateFrom = setTimeout(() => {
    window.history.pushState(fromISO, null, fromToUrl(fromISO));
  }, URL_UPDATE_DELAY);
};

const setInitialHistory = from => {
  const fromISO = formatDateISO(from);
  window.history.replaceState(fromISO, null, fromToUrl(fromISO));
};

function onPopState({ state: startDate }) {
  const momentStartDate = parseDateISO(startDate);
  if (!momentStartDate) return;
  this.setState({
    startDate: momentStartDate,
    endDate: nextSaturday(momentStartDate)
  });
}

function onDatesChange({ startDate, endDate }) {
  this.setState({ startDate, endDate });
  updateURL.bind(this, startDate);
}

function onClearDates() {
  // `currentPrice` with no `date` argument returns `lowestPrice`
  const lowestPrice = currentPrice(this.state.boat.prices);
  const { from, to } = lowestPrice;
  this.setState({ startDate: from, endDate: to });
  updateURL.bind(this, from);
}

export {
  boatUri,
  onDatesChange,
  onClearDates,
  updateURL,
  onPopState,
  setInitialHistory,
  fromToUrl
};
