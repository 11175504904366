import React from "react";
import { connectRange } from "react-instantsearch-dom";
import PropTypes from "prop-types";
import linear from "rheostat/lib/algorithms/linear";
import { generateSnapPoints } from "../utils/range";
import { Range } from "./Range";
import RangeValues from "./RangeValue";

const maxRange = 10;
const snapPointsInterval = 1;
const minRange = 0;

const snapPoints = generateSnapPoints(minRange, maxRange, snapPointsInterval);

const CabinsRange = ({ canRefine, currentRefinement, max, min, refine }) => (
  <Range
    algorithm={linear}
    canRefine={canRefine}
    currentRefinement={currentRefinement}
    refine={refine}
    maxRange={maxRange}
    minRange={minRange}
    snapPoints={snapPoints}
    max={max}
    min={min}
    displayComponent={<RangeValues />}
  />
);

const ConnectedCabinsRange = connectRange(CabinsRange);

CabinsRange.propTypes = {
  canRefine: PropTypes.bool,
  currentRefinement: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  max: PropTypes.number,
  min: PropTypes.number,
  refine: PropTypes.func
};

CabinsRange.defaultProps = {
  canRefine: false,
  currentRefinement: { min: 0, max: 100 },
  max: 10,
  min: 0,
  refine: () => {}
};

export default ConnectedCabinsRange;
