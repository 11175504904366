import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";

import App from "./App";
import { unregister } from "./registerServiceWorker";
import "../node_modules/react-geosuggest/module/geosuggest.css";

Sentry.init({
  dsn: "https://3c1339c14f01475bb88d2f6c673643af@sentry.io/2496171"
});

ReactDOM.render(<App />, document.getElementById("root"));
unregister();
